<template>
  <div class="bg-transparent">
    <!-- #033639 -->
    <b-container fluid class="shadow" style="background: #033639;">
        <!-- <br> -->
            <b-row class="pt-3">
                <b-col>
                <card class="header-card-css">
                    <b-row>
                    <b-col align-self="center">{{time}}</b-col>
                    <b-col align-self="center" xl="2" class="d-flex align-items-center">
                        <span style="font-weight:bold;">Date&nbsp&nbsp</span>
                        <base-input
                            name="Data Date"
                            type="date"
                            v-model="model.data_date"
                            @change="changeAllChart()"
                            class="mt-2 mb--3"
                        >
                        </base-input>
                    </b-col>
                    <b-col align-self="center" xl="2" class="d-flex align-items-center">
                        <span style="font-weight:bold;">Developer&nbsp&nbsp</span>
                        <base-input
                        name="Developer"
                        v-model="model.developer_id"
                        class="mt-2 mb--3"
                        >
                        <select
                            class="form-control"
                            v-model="model.developer_id"
                            @change="changeAllChart()"
                        >
                            <option
                            v-for="item in developers"
                            :key="item.developer_id"
                            :value="item.developer_id"
                            >
                            {{ item.developer_name }}
                            </option>
                        </select>
                        </base-input>
                    </b-col>
                    </b-row>
                </card>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                <card class="bg-transparent data-card-css">
                    <b-row>
                    <b-col>
                        <b-row>
                            <b-col xl="9">
                                <card class="last-total-card-css">
                                    <div id="map-custom" class="map-canvas" style="height: 600px;"></div>
                                </card>
                            </b-col>
                            <b-col xl="3" class="d-flex" style="flex-direction:column">
                                <card class="flex-grow-1 total-card-css">
                                    <div class="card-title">TOTAL CAPACITY</div>
                                    <div class="card-body">
                                        <div class="px-2 mx-2"><b-row style="align-items: flex-end;">
                                            <b-col xl="4" align-self="center">
                                                <img src="/img/icons/dashboard/01_solar_panel.png" style="padding: 25px; max-width: 100%;">
                                            </b-col>
                                            <b-col xl="5" align-self="center" class="pb-0 mb-0"><p class="pb-0 mb-0" style="color: #21BCB8;font-size: 48px; font-weight: 600;">{{total_data.total_capacity_mwp}}</p></b-col>
                                            <b-col xl="3" align-self="center" class="pb-0 mb-0"><p class="pb-0 ml--3 mb--3" style="color: white;font-size: 24px; font-weight: 600;">MWp</p></b-col>
                                        </b-row></div>
                                    </div>
                                </card>
                                <card class="flex-grow-1 total-card-css">
                                    <div class="card-title">TOTAL TURN ON</div>
                                    <div class="card-body">
                                        <div class="px-2 mx-2"><b-row style="align-items: flex-end;">
                                            <b-col xl="4" align-self="center">
                                                <img src="/img/icons/dashboard/02_turn_on.png" style="padding: 25px; max-width: 100%;">
                                            </b-col>
                                            <b-col xl="5" align-self="center" class="pb-0 mb-0"><p class="pb-0 mb-0" style="color: #21BCB8;font-size: 48px; font-weight: 600;">{{total_data.total_turn_on_capacity_mwp}}</p></b-col>
                                            <b-col xl="3" align-self="center" class="pb-0 mb-0"><p class="pb-0 ml--3 mb--3" style="color: white;font-size: 24px; font-weight: 600;">MWp</p></b-col>
                                        </b-row></div>
                                    </div>
                                </card>
                                <card class="flex-grow-1 total-card-css last-total-card-css">
                                    <div class="card-title">TOTAL SITE TURN ON</div>
                                    <div class="card-body">
                                        <div class="px-2 mx-2"><b-row style="align-items: flex-end;">
                                            <b-col xl="4" align-self="center">
                                                <img src="/img/icons/dashboard/03_location.png" style="padding: 25px; max-width: 100%;">
                                            </b-col>
                                            <b-col xl="8" align-self="center" class="pb-0 mb-0"><p class="pb-0 mb-0" style="color: #21BCB8;font-size: 48px; font-weight: 600;">{{total_data.total_site_turn_on}}</p></b-col>
                                            </b-row></div>
                                    </div>
                                </card>
                            </b-col>
                        </b-row>
                    </b-col>
                    </b-row>
                </card>
                </b-col>
            </b-row>
            <br>
            <b-row>
                <b-col>
                <card class="bg-transparent data-card-css">
                    <b-row>
                    <b-col class="d-flex" style="flex-direction:column">
                        <card class="flex-grow-1 chart-card-css">
                            <div class="card-title">Completed C&I Projects</div>
                            <apexchart
                                ref="completedIndustryPieChart"
                                type="pie"
                                height="400px"
                                :options="chartOptionsCompletedIndustry"
                                :series="seriesCompletedIndustry"
                            ></apexchart>
                        </card>
                    </b-col>
                    <b-col class="d-flex" style="flex-direction:column">
                        <card class="flex-grow-1 chart-card-css">
                            <div class="card-title">On-Going C&I Projects</div>
                            <apexchart
                                ref="ongoingProjectsBarChartGrouped"
                                type="bar"
                                height="400px"
                                :options="chartOptionsOngoingProjectsGrouped"
                                :series="seriesBarOngoingProjectsGrouped"
                                @dataPointSelection="dataPointSelectionHandlerBar"
                          ></apexchart>
                        </card>
                    </b-col>
                    </b-row>
                </card>
                </b-col>
            </b-row>
    </b-container>

    <!-- :no-close-on-backdrop="true" -->
    <!-- body-classes="p-0" -->
    <modal
      :show.sync="isOngoingProjectsBarChartVisible"
      size="xl"
      body-classes="p-0"
      title="On-Going C&I Projects Detailed"
    >
      <card class="data-card-css chart-card-css">
        <div class="card-title">{{modalTitle}}</div>
        <!-- <template> -->
          <!-- <h1 class="text-center">On-Going C&I Projects Detailed</h1> -->
            <InnerBarChart :chartOptions="chartOptionsInnerBarChart" :seriesData="seriesInnerBarChart"/>
        <!-- </template> -->
      </card>
    </modal>
  </div>
</template>

<script>
import { Table, TableColumn, Select, Option } from 'element-ui';
import Fuse from 'fuse.js';

import {
  LoginURL,
  clientId,
  clientSecret,
  apiDomain,
  getHeader,
  getcurrentDate,
} from "@/assets/js/config.js";
import axios from "axios";
import ErrorFunctionMixin from "@/assets/js/errorFunctionMix.js";
import VueApexCharts from "vue-apexcharts";
import Card from "../../../components/Cards/Card.vue";
import InnerBarChart from '@/views/SemCorp/Template/Dashboard/InnerBarChart.vue'

import { API_KEY } from '@/views/Maps/API_KEY';
import GoogleMapsLoader from 'google-maps';

GoogleMapsLoader.KEY = API_KEY;

export default {
  mixins: [ErrorFunctionMixin],
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    apexchart: VueApexCharts,
    InnerBarChart
  },
  beforeDestroy() {
    // prevent memory leak
    clearInterval(this.interval);
    clearInterval(this.dataDateInterval);
  },
  created() {
    // update the time every second
    this.interval = setInterval(() => {
      // Concise way to format time according to system locale.
      // In my case this returns "3:48:00 am"
      this.time = Intl.DateTimeFormat('en-GB', {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false,
        }).format();
    
    }, 1000)

    // Schedule checkAndUpdateDataDate every 10 minutes
    this.dataDateInterval = setInterval(() => {
        this.checkAndUpdateDataDate();
    }, 600000); // 10 minutes in milliseconds

    this.getDeveloperOptions();
    var currentDate = getcurrentDate();
    this.model.data_date = currentDate;
    this.changeAllChart();
  },
  computed: {
  },
  data() {
    return {
        interval: null,
        time: null,
        dataDateInterval: null,
        developers: [],
        model: {
            // developer_id: null,
            developer_id: 1,
            data_date: null,
        },
        modalTitle: "On-Going C&I Projects Detailed",
        sampleMarkers: [
            // // 1.3376750320687931, 103.84542670450928
            // {
            //     latitude: 1.337675,
            //     longitude: 103.845426,
            //     title: "Sembcorp Solar Singapore Pte Ltd",
            //     address: "5 Toa Payoh W, #05-01, Singapore 318877",
            //     kwp: 234,
            //     status: "IN PROGRESS",
            //     cod: null,
            // },
            // // 1.275378156765027, 103.85160651385254
            // {
            //     latitude: 1.275378,
            //     longitude: 103.851606,
            //     title: "Sembcorp Properties Pte. Ltd.",
            //     address: "2 Lim Teck Kim Rd, Singapore 088934",
            //     kwp: 345,
            //     status: "COMPLETED",
            //     cod: "5 Jun 2023"
            // },
        ],
        map_dashboard_data_response: [],
        total_data: {
            total_capacity_mwp: null,
            total_turn_on_capacity_mwp: null,
            total_site: null,
        },
        chartOptionsCompletedIndustry: {
            chart: {
            // width: 380,
            type: "pie",
            },
            labels: [
            "Automotive",
            "Electronics",
            "Food & Beverages",
            "Properties",
            "Chemical",
            "Health",
            "Others",
            ],
            legend: {
                labels: {
                    colors: "#FFFFFF",
                },
                // formatter: function (val) {
                //     return val + " %";
                // },
                //  formatter: function (val, opts) => {
                //     return this.seriesCompletedIndustry.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
                //     // return val + " " + opts.w.config.series[opts.seriesIndex]
                // },
                formatter: (val, opts) => {
                    const total = this.seriesCompletedIndustry.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
                    if ( total == 0 ) {
                        return val;
                    } else {
                        const percentage = ((opts.w.config.series[opts.seriesIndex] / total) * 100).toFixed(1);
                        return val + " " + percentage + "%";
                    }
                    
                },
            },
            colors: [
                "#4270C1",
                "#E97C30",
                "#A3A3A3",
                "#FBBD00",
                "#5A99D3",
                "#6EAB46",
                "#244276",
            // "#549e39",
            // "#c0cf3a",
            // "#4ab5c4",
            // "#325f22",
            // "#76801f",
            // "#28707a",
            // "#549e39",
            ],
            responsive: [
            {
                // breakpoint: 1,
                options: {
                chart: {
                    width: 200,
                },
                legend: {
                    position: "bottom",
                },
                },
            },
            ],
            tooltip: {
            y: {
                formatter: function (val) {
                return val + " kWp";
                },
            },
            },
        },
        seriesCompletedIndustry: [1, 1, 1, 1, 1, 1, 1],
        chartOptionsOngoingProjectsGrouped: {
            chart: {
            // height: 350,
            type: "bar",
            events: {
                click: function (chart, w, e) {
                // console.log(chart, w, e)
                },
            },
            },
            colors: [
                "#A1EDEB",
                "#76E7E2",
                "#5CE1DF",
                "#1DA29F",
                "#83E9E7",
                "#2FDAD5",
                "#1DA29F",
                "#178583",
            // "#549e39",
            // "#c0cf3a",
            // "#4ab5c4",
            // "#325f22",
            // "#76801f",
            // "#28707a",
            ],
            plotOptions: {
            bar: {
                dataLabels: {
                position: "top", // top, center, bottom
                },
                distributed: true,
            },
            },
            dataLabels: {
                formatter: function (value) {
                    return value + " kWp";
                },
                // offsetY: -20,
                },
                    stroke: {
                    width: 1,
                    colors: ["#fff"],
                },
            legend: {
                show: false,
            },
            xaxis: {
            // title: {
            //     text: "MILESTONES",
            //     style: { color: "#55c8e9", fontSize: "16px" },
            // },
            categories: [
                "Installation to Start",
                "Installation In Progress",
                "Installation Completed"
                ],
                labels: {
                    style: {
                        colors: 
                            "#fff"
                        ,
                    },
                },
            },
            yaxis: {
            // title: {
            //     text: "CAPACITY (KWP)",
            //     style: { color: "#55c8e9", fontSize: "16px" },
            // },
            labels: { style: { colors: ["#FFFFFF"] } },
            },
            tooltip: {
                enabled: false,
            // y: {
            //     formatter: function (val) {
            //     return val + " kWp";
            //     },
            //     title: {
            //     formatter: function (val) {
            //         return "Capacity:";
            //     },
            //     },
            // },
            },
        },
        seriesBarOngoingProjectsGrouped: [
            {
            name: "",
            data: [0, 0, 0],
            // extra: [1, 2, 3, 4, 5, 6, 7, 8],
            },
        ],
        isOngoingProjectsBarChartVisible: false,
        chartKey: 0,
        chartOptionsInnerBarChart: {
            chart: {
            type: "bar",
            },
            colors: [
            "#A1EDEB",
                "#76E7E2",
                "#5CE1DF",
                "#1DA29F",
                "#83E9E7",
                "#2FDAD5",
                "#1DA29F",
                "#178583",
            ],
            plotOptions: {
            bar: {
                dataLabels: {
                position: "top", // top, center, bottom
                },
                distributed: true,
            },
            },
            dataLabels: {
                formatter: function (value) {
                    return value + " kWp";
                },
                },
                    stroke: {
                    width: 1,
                    colors: ["#fff"],
                },
            legend: {
                show: false,
            },
            xaxis: {
                categories: [
                    "Installation to Start",
                    "Installation In Progress",
                    "Installation Completed"
                ],
                labels: {
                    style: {
                        colors: 
                            "#fff"
                        ,
                    },
                },
            },
            yaxis: {
            // title: {
            //     text: "CAPACITY (KWP)",
            //     style: { color: "#55c8e9", fontSize: "16px" },
            // },
            labels: { style: { colors: ["#FFFFFF"] } },
            },
            tooltip: {
                enabled: false,
            // y: {
            //     formatter: function (val) {
            //     return val + " kWp";
            //     },
            //     title: {
            //     formatter: function (val) {
            //         return "Capacity:";
            //     },
            //     },
            // },
            },
        },
        seriesInnerBarChart: [
            {
            name: "",
            data: [0, 0, 0],
            // extra: [1, 2, 3, 4, 5, 6, 7, 8],
            },
        ],
    };
  },
  methods: {
    checkAndUpdateDataDate() {
        const currentDate = getcurrentDate();
        if (this.model.data_date !== currentDate) {
            this.model.data_date = currentDate;
            this.changeAllChart();
        }
    },
    loadGoogleMap() {
        GoogleMapsLoader.load(google => {
            this.initMap(google);
        });
    },
    initMap(google) {
        // let map, lat = 40.748817, lng = -73.985428, color = "#5e72e4";
        // 1.3500378443076655, 103.80922814414053
        // -2.3814623,107.2319264

        let map, lat = 1.350037, lng = 103.809228, color = "#5e72e4", zoomLevel = 12;
        if ( this.model.developer_id == 1 ) {
            lat = 1.350037;
            lng = 103.809228;
            zoomLevel = 12;
        } else if ( this.model.developer_id == 2 ) {
            lat = -2.381462;
            lng = 107.231926;
            zoomLevel = 5;
        }
        map = document.getElementById('map-custom');

        let myLatlng = new google.maps.LatLng(lat, lng);
        let mapOptions = {
        //   zoom: 12,
            zoom: zoomLevel,
          scrollwheel: false,
          center: myLatlng,
        //   mapTypeId: google.maps.MapTypeId.ROADMAP,
        mapTypeId: google.maps.MapTypeId.HYBRID,
          styles: [
            {
            "featureType": "poi",
            "elementType": "all",
            "stylers": [
                {
                "visibility": "off"
                }
            ]
            },
            {
            "featureType": "road",
            "elementType": "all",
            "stylers": [
                {
                "visibility": "off"
                }
            ]
            },
            ],
            disableDefaultUI: true,
            zoomControl: true,
            fullscreenControl: true,
             keyboardShortcuts: false,
        }

        map = new google.maps.Map(map, mapOptions);

        // let marker = new google.maps.Marker({
        //   position: myLatlng,
        //   map: map,
        //   animation: google.maps.Animation.DROP,
        //   title: 'Hello World!'
        // });

        // let contentString = '<div class="info-window-content"><h2>Argon Dashboard PRO</h2>' +
        //   '<p>A beautiful premium dashboard for Bootstrap 4.</p></div>';

        // let infowindow = new google.maps.InfoWindow({
        //   content: contentString
        // });

        // google.maps.event.addListener(marker, 'click', function () {
        //   infowindow.open(map, marker);
        // });

        // const inProgressIcon = 'http://maps.google.com/mapfiles/ms/icons/red-dot.png';
        const inProgressIcon = 'http://maps.google.com/mapfiles/ms/icons/yellow-dot.png';
        const completedIcon = 'http://maps.google.com/mapfiles/ms/icons/green-dot.png';

        this.sampleMarkers.forEach(item => {
            const position = { lat: item.latitude, lng: item.longitude }; // Replace with your API data
            // <h2>${item.title}</h2>
            let contentString = '';

            if ( item.status == "IN PROGRESS" ) {
                //  max-height: 300px;
                contentString = `<div class="info-window-content" style="max-width: 300px;">
                <p style="padding: 0px; margin: 0px; color: white; font-weight:bold;">${item.title}</p>
                <p style="padding: 0px; margin: 0px; color: white; font-weight:bold; font-size:14px;">${item.address}</p>
                <p align="right" style="padding: 0px; margin: 0px;"><span style="color: #0D4D4A; font-weight: 600; font-size:24px;">${item.kwp}</span>&nbsp&nbsp<span style="color: white; font-weight:bold">kWp</span></p>
                <p align="right" style="padding: 0px; margin: 0px; color: white; font-weight:500;">${item.status}</p>
                </div>`;
            } else if ( item.status == "COMPLETED" ) {
                //  max-height: 300px;
                contentString = `<div class="info-window-content" style="max-width: 300px;">
                <p style="padding: 0px; margin: 0px; color: white; font-weight:bold;">${item.title}</p>
                <p style="padding: 0px; margin: 0px; color: white; font-weight:bold; font-size:14px;">${item.address}</p>
                <p align="right" style="padding: 0px; margin: 0px;"><span style="color: #0D4D4A; font-weight: 600; font-size:24px;">${item.kwp}</span>&nbsp&nbsp<span style="color: white; font-weight:bold">kWp</span></p>
                <p align="right" style="padding: 0px; margin: 0px; color: white; font-weight:500;">COD: ${item.cod}</p>
                </div>`;
            }
            
            // Set the marker icon based on the status
            let markerIcon = null;
            if (item.status === "IN PROGRESS") {
                markerIcon = inProgressIcon;
            } else if (item.status === "COMPLETED") {
                markerIcon = completedIcon;
            }

            const marker = new google.maps.Marker({
                position: position,
                map: map,
                animation: google.maps.Animation.DROP,
                title: item.title, // Replace with your API data
                icon: markerIcon, // Set the marker icon
            });

            const infowindow = new google.maps.InfoWindow({
                content: contentString,
            });

            // Attach click event listener to display the info window when the marker is clicked
            google.maps.event.addListener(marker, 'click', function () {
                infowindow.open(map, marker);
            });
        });
      },
    reloadMap() {
        this.loadGoogleMap();
    },
    getDeveloperOptions() {
        axios
        .get(apiDomain + "setup/developer", { headers: getHeader() })
        .then((response) => {
            if (response.status === 200) {
                this.developers = response.data.data;
                // default to sembcorp solar || developer_id = 1
                this.model.developer_id = 1;
                this.getMapDashboardData();
            }
        })
        .catch((error) => {
            console.log(error.response.status);
            this.errorFunction(error, "Developer Information");
        });
    },
    getMapDashboardData() {
        axios
        .get(apiDomain + "dashboard/map_dashboard_data/" + this.model.developer_id + "/" + this.model.data_date, { headers: getHeader() })
        .then((response) => {
            if (response.status === 200) {
                this.map_dashboard_data_response = response.data.data[0];
                this.total_data.total_capacity_mwp = (response.data.data[0].total_capacity_kwp / 1000).toFixed(2);
                this.total_data.total_turn_on_capacity_mwp = (response.data.data[0].total_turn_on_capacity_kwp / 1000).toFixed(2);
                this.total_data.total_site = response.data.data[0].total_site;
                this.total_data.total_site_turn_on = response.data.data[0].total_site_turn_on;
                this.seriesCompletedIndustry = [
                    response.data.data[0].industry_category_id_1_kwp,
                    response.data.data[0].industry_category_id_2_kwp,
                    response.data.data[0].industry_category_id_3_kwp,
                    response.data.data[0].industry_category_id_4_kwp,
                    response.data.data[0].industry_category_id_5_kwp,
                    response.data.data[0].industry_category_id_6_kwp,
                    response.data.data[0].industry_category_id_7_kwp,
                ];
                let installation_to_start = this.map_dashboard_data_response.site_status_id_8_kwp +
                                            this.map_dashboard_data_response.site_status_id_9_kwp +
                                            this.map_dashboard_data_response.site_status_id_10_kwp +
                                            this.map_dashboard_data_response.site_status_id_11_kwp;
                let installation_in_progress = this.map_dashboard_data_response.site_status_id_12_kwp +
                                                this.map_dashboard_data_response.site_status_id_13_kwp +
                                                this.map_dashboard_data_response.site_status_id_14_kwp;
                let installation_completed = this.map_dashboard_data_response.site_status_id_15_kwp;
                this.seriesBarOngoingProjectsGrouped = [
                    {
                        data: [
                            // Installation to Start
                            installation_to_start.toFixed(2),
                            // Installation In Progress
                            installation_in_progress.toFixed(2),
                            // Installation Completed
                            installation_completed.toFixed(2),
                        ],
                    }
                ];
            }
        })
        .catch((error) => {
            console.log(error.response.status);
            this.errorFunction(error, "Map Dashboard Information");
        });
    },
    dataPointSelectionHandlerBar(e, chartContext, config) {
        // let tempBarData = [config.seriesIndex, config.dataPointIndex];

        if ( config.dataPointIndex == 0 ) {
            // Installation to Start
            this.modalTitle = "Installation to Start";
            this.chartOptionsInnerBarChart.xaxis.categories = [
                "00.Pending Project Starts",
                "01.Site Survey In Progress, Preparing KOM",
                "02.KOM Completed",
                "03.Pending Roof Handover",
            ];
            this.seriesInnerBarChart = [
                {
                    data: [
                        this.map_dashboard_data_response.site_status_id_8_kwp.toFixed(2),
                        this.map_dashboard_data_response.site_status_id_9_kwp.toFixed(2),
                        this.map_dashboard_data_response.site_status_id_10_kwp.toFixed(2),
                        this.map_dashboard_data_response.site_status_id_11_kwp.toFixed(2),
                    ],
                }
            ];
        } else if ( config.dataPointIndex == 1 ) {
            // Installation In Progress
            this.modalTitle = "Installation In Progress";
            this.chartOptionsInnerBarChart.xaxis.categories = [
                "04.Hoisting & Installation In Progress",
                "05.Installation Completed, Pending T&C",
                "06.Tie-In Completed",
            ];
            this.seriesInnerBarChart = [
                {
                    data: [
                        this.map_dashboard_data_response.site_status_id_12_kwp.toFixed(2),
                        this.map_dashboard_data_response.site_status_id_13_kwp.toFixed(2),
                        this.map_dashboard_data_response.site_status_id_14_kwp.toFixed(2),
                    ],
                }
            ];
        } else if ( config.dataPointIndex == 2 ) {
            // Installation Completed
            this.modalTitle = "Installation Completed";
            this.chartOptionsInnerBarChart.xaxis.categories = [
                "07.Pending Turn On",
            ];
            this.seriesInnerBarChart = [
                {
                    data: [
                        this.map_dashboard_data_response.site_status_id_15_kwp,
                    ],
                }
            ];
        }

        this.openInnerBarChart();
    },
    openInnerBarChart() {
        this.isOngoingProjectsBarChartVisible = true;
    },
    closeInnerBarChart() {
        this.isOngoingProjectsBarChartVisible = false;
    },
    getMapDashboardSiteData() {
        axios
        .get(apiDomain + "dashboard/map_dashboard_site_data/" + this.model.developer_id, { headers: getHeader() })
        .then((response) => {
            if (response.status === 200) {

                let tempData = response.data.data;
                let tempArray = [];

                tempData.forEach(item => {
                    // check if project considered completed

                    // if ( item.status == "IN PROGRESS" ) {
                    // } else if ( item.status == "COMPLETED" ) {

                    let status_name = ""

                    if ( item.project_tag == "legacy" ) {
                        if ( item.status_id == 5 ||  item.status_id == 7 ) {
                            status_name = "COMPLETED"
                        } else {
                            status_name = "IN PROGRESS"
                        }
                    } else if ( item.project_tag == "singapore_v2" ) {
                        if ( item.status_id == 16 ||  item.status_id == 17 ) {
                            status_name = "COMPLETED"
                        } else {
                            status_name = "IN PROGRESS"
                        }
                    }

                    // if completed show turn on date
                    let tempItem = {
                        latitude: item.site_latitude,
                        longitude: item.site_longitude,
                        title: item.site_name,
                        address: item.site_address + " (" + item.site_postal_code + ")",
                        kwp: item.site_total_capacity.toFixed(2),
                        status: status_name,
                        cod: item.site_actual_turn_on_date ? item.site_actual_turn_on_date : "N/A",
                    };

                    tempArray.push(tempItem);
                });

                this.sampleMarkers = tempArray;
                this.reloadMap();
            }                
        })
        .catch((error) => {
            console.log(error.response.status);
            this.errorFunction(error, "Map Dashboard Site Information");
        });
    },
    changeAllChart() {
      this.getMapDashboardData();
      this.getMapDashboardSiteData();
    },    
  },
  mounted() {
    this.loadGoogleMap();
  },
};
</script>

<style>
.header-card-css {
    border-radius: 25px;
    margin-bottom: 15px;
}

.header-card-css .card-body {
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 0px;
    padding-bottom: 0px;
}

.data-card-css {
    margin-bottom: 25px;
}

.data-card-css .card-body {
    padding: 0px;
}

.total-card-css {
    border-radius: 25px;
    background-color: #005C60;
    /* padding: 0px; */
    /* margin: 0px; */
    /* border: 2px solid red; */
}

.last-total-card-css {
    margin-bottom: 0px;
}

.total-card-css .card-title {
    background-color: #21BDB9;
    color: white;
    border-radius: 50px 50px 0px 0px;
    padding-left: 25px;
    font-weight: 600;
    /* padding: 0px; */
    /* margin: 0px; */
    /* margin-left: 30px; */
    /* margin-right: 30px; */
    /* border: 2px solid red; */
}

.total-card-css .card-body {
    /* background-color: yellow; */
    color: white;
    padding: 0px;
    /* margin: 0px; */
    /* border: 2px solid red; */
}

.chart-card-css {
    /* border-radius: 25px; */
    background-color: #005C60;
    /* padding: 0px; */
    margin-bottom: 0px;
    /* margin: 0px; */
    /* border: 2px solid red; */
}

.chart-card-css .card-title {
    background-color: #21BDB9;
    color: white;
    border-radius: 50px 50px 50px 50px;
    padding-left: 25px;
    padding-top: 12.5px;
    padding-bottom: 12.5px;
    margin-left: 25px;
    margin-right: 50%;
    margin-top: -25px;
    font-weight: 600;
    /* padding: 0px; */
    /* margin: 0px; */
    /* margin-left: 30px; */
    /* margin-right: 30px; */
    /* border: 2px solid red; */
}

.chart-card-css .card-body {
    /* background-color: yellow; */
    color: white;
    padding: 0px;
    /* padding-left: 0px;
    padding-right: 0px;
    padding-top: 0px;
    padding-bottom: 0px; */
    /* margin: 0px; */
    /* border: 2px solid red; */
}

.gm-style .gm-style-iw-d::-webkit-scrollbar-track,
.gm-style .gm-style-iw-d::-webkit-scrollbar-track-piece,
.gm-style .gm-style-iw-c,
.gm-style .gm-style-iw-t::after {
    background: #69B6B0;
    opacity: 0.9; 
}

.gm-style .gm-style-iw-tc::after {
    background: #69B6B0;
    opacity: 0.9; 
}
</style>