<template>
  <div class="content">
    <base-header class="pb-6">
      <b-row align-v="center" class="py-4">
        <b-col cols="7" lg="6">
          <h6 class="h2 text-white d-inline-block mb-0">Safety Inspection Report</h6>
          <!-- <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav> -->
        </b-col>
      </b-row>
    </base-header>

    <b-container fluid class="mt--6">
      <div>
        <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2">
          <!-- <template slot="header">
            <h3 class="mb-0">Safety Inspection Report</h3>
          </template> -->
          <div>
            <b-col cols="12" class="d-flex justify-content-center justify-content-sm-between flex-wrap">
            </b-col>

            <b-col cols="12">
              <h3>Filters</h3>

              <div class="form-row">
                <b-col cols="3">
                  <base-input
                    label="Group By"
                    name="Group By"
                    v-model="filter.group_by"
                  >
                  <select class="form-control" v-model="filter.group_by" @change="toggleContractorsSafetyOfficers">
                    <option value="x">Project/EPC</option>
                    <option value="y">Safety Officer</option>
                  </select>
                  </base-input>
                </b-col>

                <b-col cols="3">
                  <base-input
                          label="Report Type"
                          name="Report Type"
                          v-model="filter.report_type"
                  >
                    <select class="form-control" v-model="filter.report_type">
                      <option selected :value=null>All</option>
                      <option value="epc_hse_report">EPC's HSE Report</option>
                      <option value="sembcorp_hse_report">Sembcorp Solar's HSE Report</option>
                    </select>
                  </base-input>
                </b-col>

                <b-col cols="3">
                  <base-input
                          label="Safety Inspection Type"
                          name="Safety Inspection Type"
                          v-model="filter.safety_inspection_type_id"
                  >
                    <select class="form-control" v-model="filter.safety_inspection_type_id">
                      <option selected :value=null>All</option>
                      <option v-for="item in safety_inspection_type" :key="item.safety_inspection_type_id" v-bind:value="item.safety_inspection_type_id">
                        {{ item.safety_inspection_type_code }}
                      </option>
                    </select>
                  </base-input>
                </b-col>
              </div>

              <div class="form-row">
                <b-col cols="3">
                  <base-input
                    label="From Date"
                    name="From Date"
                    type="date"
                    v-model="filter.from_date"
                  >
                  </base-input>
                </b-col>

                <b-col cols="3">
                  <base-input
                    label="To Date"
                    name="To Date"
                    type="date"
                    v-model="filter.to_date"
                    :max="new Date().toISOString().split('T')[0]"
                  >
                  </base-input>
                </b-col>

                <b-col cols="3">
                  <base-input
                    label="Site Type"
                    name="Site Type"
                    v-model="filter.site_type"
                  >
                    <select class="form-control" v-model="filter.site_type">
                      <option selected :value=null>All</option>
                      <option value="x">On Going</option>
                      <option value="y">Completed</option>
                    </select>
                  </base-input>
                </b-col>

                <b-col cols="3">
                  <base-input
                    label="Safety Inspection Submitter"
                    name="Safety Inspection Submitter"
                    v-model="filter.site_type"
                  >
                    <select class="form-control" v-model="filter.safety_observation_creator">
                      <option selected :value=null>All</option>
                      <option 
                        v-for="item in safety_observation_creators"
                        :key="item.id"
                        v-bind:value="item.id">
                        {{ item.name }}
                      </option>
                    </select>
                  </base-input>
                </b-col>
              </div>

              <div v-if="filter.group_by == 'x'">
                <b-row>
                  <b-col cols="6">
                    <h4>Contractors</h4>
                    <div>
                      <label>
                        <input type="checkbox" v-model="selectAllContractors" @change="toggleselectAllContractors">
                        Select All
                      </label>
                    </div>
                    <div v-for="(contractor, index) in contractors" :key="index">
                      &nbsp;&nbsp;
                      <label>
                        <input type="checkbox" v-model="selectedContractors" :value="contractor.contractor_id">
                        {{ contractor.contractor_code }}
                      </label>
                    </div>
                    <!-- <div>
                      selectAllContractors: {{selectAllContractors}}
                    </div>
                    <div>
                      selectedContractors: {{selectedContractors}}
                    </div> -->
                  </b-col>

                  <b-col cols="6">
                    <h4>Safety Officers</h4>
                    <div>
                      <label>
                        <input type="checkbox" v-model="selectAllSafetyOfficers" @change="toggleselectAllSafetyOfficers">
                        Select All
                      </label>
                    </div>
                    <div v-for="(safety_officer, index) in safety_officers" :key="index">
                      &nbsp;&nbsp;
                      <label>
                        <input type="checkbox" v-model="selectedSafetyOfficers" :value="safety_officer.id">
                        {{ safety_officer.name }}
                      </label>
                    </div>
                    <!-- <div>
                      selectAllSafetyOfficers: {{selectAllSafetyOfficers}}
                    </div>
                    <div>
                      selectedSafetyOfficers: {{selectedSafetyOfficers}}
                    </div> -->
                  </b-col>
                </b-row>
              </div>
              <br/>

              <b-row>
                <b-col class="text-right">
                  <base-button size="xl" type="primary" @click="generateExcelReportFiltered()">
                    GENERATE EXCEL REPORT FILTERED
                  </base-button>
                </b-col>
              </b-row>
            </b-col>
            <br/>            
          </div>
        
        </card>
      </div>
    </b-container>
    </div>
</template>
<script>
import { Table, TableColumn, Select, Option } from 'element-ui';
import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb'
import { BasePagination } from '@/components';
import standardPaginationMix from '@/assets/js/standardPaginationMix.js'
import axios from 'axios'
import Fuse from 'fuse.js';
import {LoginURL, clientId, clientSecret, apiDomain, getHeader} from '@/assets/js/config.js'
import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'

export default {
  mixins: [standardPaginationMix, ErrorFunctionMixin],
  components: {
    BasePagination,
    RouteBreadCrumb,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  created()
  {
    var safety_observation_report_access = JSON.parse(window.localStorage.getItem('safety_observation_report_access'))
    this.button_access.new_button = safety_observation_report_access.access_create
    this.button_access.show_button = safety_observation_report_access.access_show
    this.getSafetyInspectionTypes();
    this.getContractorList();
    this.getSafetyOfficerList();
    this.getSafetyObservationCreatorList();
    // this.getAuditLogProject()
  },
  data() {
    return {
      button_access:{
        new_button: null,
        show_button: null,
      },
      filter: {
        group_by: "x",
        from_date: null,
        to_date: null,
        site_type: null,
        contractor_ids: null,
        safety_officer_ids: null,
        safety_observation_creator: null,
        report_type:null,
        safety_inspection_type_id: null,
      },
      generate_report_response: null,
      safety_inspection_type: [],
      contractors: [],
      selectedContractors: [],
      selectAllContractors: false,
      safety_officers: [],
      selectedSafetyOfficers: [],
      safety_observation_creators: [],
      selectAllSafetyOfficers: false,
    };
  },
  methods: {
    toggleContractorsSafetyOfficers(){
      console.log('toggleContractorsSafetyOfficers(){');
      if (this.filter.group_by == "y") {
        console.log('if (this.filter.group_by == "y") {');
        this.selectAllSafetyOfficers = false;
        this.selectedSafetyOfficers = [];
        this.selectAllContractors = false;
        this.selectedContractors = [];
      }
    },
    toggleselectAllSafetyOfficers() {
      if (this.selectAllSafetyOfficers) {
        this.selectedSafetyOfficers = this.safety_officers.map(so => so.id);
      } else {
        this.selectedSafetyOfficers = [];
      }
    },
    getSafetyObservationCreatorList() {
      axios
      .get(apiDomain + "lookup/user_list.safety_observation_creator", { headers: getHeader() })
      .then((response) => {
        if (response.status === 200) {
          this.safety_observation_creators = response.data.data;
        }
      })
      .catch((error) => {
        console.log(error.response.status);
        this.errorFunction(error, "Safety Observation Creator Information");
      });
    },

    // CHANGE TO GET SAFETY OFFICER FROM PROJECT LIST
    getSafetyOfficerList() {
      axios
      .get(apiDomain + "lookup/user_list.project_safety", { headers: getHeader() })
      .then((response) => {
        if (response.status === 200) {
          this.safety_officers = response.data.data;
        }
      })
      .catch((error) => {
        console.log(error.response.status);
        this.errorFunction(error, "Safety Officer Information");
      });
    },
    toggleselectAllContractors() {
      if (this.selectAllContractors) {
        this.selectedContractors = this.contractors.map(c => c.contractor_id);
      } else {
        this.selectedContractors = [];
      }
    },
    getSafetyInspectionTypes()
    {
      axios.get(apiDomain + 'lookup/safety_inspection_type', { headers: getHeader() })
      .then ( response => {
        if (response.status === 200)
        {
          this.safety_inspection_type = response.data.data;
        }
      })
      .catch ( error => {
        console.log(error.response.status)
        this.errorFunction(error, "Safety Inspection Type Information")
      } );
    },
    getContractorList() {
      axios.get(apiDomain + 'setup/contractor', { headers: getHeader() })
      .then ( response => {
        if (response.status === 200)
        {
          this.contractors = response.data.data
        }
      })
      .catch ( error => {
        console.log(error.response.status)
        this.errorFunction(error, "Contractor Listing")
      } );
    },
    generateExcelReportFiltered() {
      if (this.selectedContractors.length == 0) {
        this.selectAllContractors = true;
        this.selectedContractors = this.contractors.map(c => c.contractor_id);
      }
      this.filter.contractor_ids = this.selectedContractors.join(",");

      if (this.selectedSafetyOfficers.length == 0) {
        this.selectAllSafetyOfficers = true;
        this.selectedSafetyOfficers = this.safety_officers.map(so => so.id);
      }
      this.filter.safety_officer_ids = this.selectedSafetyOfficers.join(",");

      axios.post(apiDomain + 'health_safety/generate_excel_report_filtered', this.filter, { headers: getHeader(), responseType: 'blob' })
        .then ( response => {
          if (response.status === 200) {
            // this.generate_report_response = response.data;
            
            // create a temporary URL to download the blob response
            const url = window.URL.createObjectURL(new Blob([response.data]));
            
            // create a link element and click it to download the file
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'safety_observation_report.xlsx');
            document.body.appendChild(link);
            link.click();
          }

        })
        .catch ( error => {
          if (error.response.status === 404) {
            this.redErrorNotify( '<b>No data found for this filter.</b>' )
          } else {
            this.errorFunction(error, "Generate Excel Report Filtered")
          }          
        });

    },    
    generateExcelReport() {
      axios.get(apiDomain + 'health_safety/generate_excel_report', { headers: getHeader(), responseType: 'blob' })
        .then ( response => {
          if (response.status === 200) {
            // this.generate_report_response = response.data;
            
            // create a temporary URL to download the blob response
            const url = window.URL.createObjectURL(new Blob([response.data]));
            
            // create a link element and click it to download the file
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'safety_observation_report.xlsx');
            document.body.appendChild(link);
            link.click();
          }
        })
        .catch ( error => {
          this.errorFunction(error, "Generate Excel Report")
        });

    },
    // getAuditLogProject() {
    //   axios.get(apiDomain + 'audit_log/audit_log_project', { headers: getHeader() })
    //     .then ( response => {
    //     if (response.status === 200)
    //     {
    //         this.tableData = response.data.data;
    //         this.fuseData();
    //         this.getUniqueTable();
    //         this.getUniqueUser();
    //     }
    //     })
    //     .catch ( error => {
    //       this.errorFunction(error, "Audit Log Project Listing")
    //     });
    // },
    searchAuditLogProject() {
      this.tempButtonStatus = true;
         axios.post(apiDomain + "audit_log/audit_log_project_search", this.filter, {
          headers: getHeader(),
        })
        .then ( response => {
        if (response.status === 200)
        {
          this.tableData = response.data.data;
          this.fuseData();
        }
        })
        .catch ( error => {
          this.errorFunction(error, "Search Audit Log Project Listing")
        });
    },
    getUniqueTable() {
      this.uniqueTable = this.tableData
        .map(item => item.audit_table)
        .filter((value, index, self) => self.indexOf(value) === index);
    },
    getUniqueUser() {
      let tempUsers = [];
      // put users for every row of data into tempUsers
      this.tableData.forEach((e) => {
        tempUsers.push(e['audit_change_by']);
      });
      // remove duplicate users from tempUsers
      tempUsers = tempUsers.filter((value, index, self) =>
        index === self.findIndex((t) => (
          t.place === value.place && t.name === value.name
        ))
      );
      this.uniqueUser = tempUsers;
      // sort by name alphabetically
      this.uniqueUser.sort((a, b) => (a.name > b.name) ? 1 : -1);
    },
  }
};
</script>
<style>
  .no-border-card .card-footer{
    border-top: 0;
  }
</style>