<template>
<div>
  <card>
    <h3 slot="header" class="mb-0">New Safety Inspection</h3>
    <div class="text-right"></div>
    <form class="needs-validation" @submit.prevent="submit">

      <card>
      <div class="form-row">
        <b-col md="3">
          <base-input label="Project"
                      name="Project"
                      v-model="model.project_id">
            <select class="form-control" v-model="model.project_id" @change="getProjectStatus(); getGroupFromProject();">
              <option Selected disabled value="x">-- Please Select A Project --</option>
              <option v-for="item in projects" :key="item.project_id" v-bind:value="item.project_id">{{ item.project_name }}</option>
            </select>
          </base-input>
        </b-col>

        <b-col md="3">
          <base-input label="Project Group"
                      name="Project Group"
                      v-model="model.group_id">
            <select class="form-control" v-model="model.group_id" @change="getSiteFromGroup()">
              <option Selected disabled value="x">-- Please Select A Project To Show Group --</option>
              <option Selected disabled value="y">-- Please Select A Group --</option>
              <option v-for="item in project_groups" :key="item.group_id" v-bind:value="item.group_id">{{ item.group_name }}</option>
            </select>
          </base-input>
        </b-col>

        <b-col md="3">
          <base-input label="Project Site"
                      name="Project Site"
                      v-model="model.site_id">
            <select class="form-control" v-model="model.site_id" @change="getContractorFromSite()">
              <option Selected disabled value="x">-- Please Select A Project Group To Show Project Site --</option>
              <option Selected disabled value="y">-- Please Select A Project Site --</option>
              <option v-for="item in project_sites" :key="item.site_id" v-bind:value="item.site_id">{{ item.site_name }}</option>
            </select>
          </base-input>
        </b-col>

        <b-col md="3">
          <base-input label="EPC"
                      name="EPC"
                      v-model="model.contractor_id">
            <select class="form-control" v-model="model.contractor_id" disabled>
              <option Selected disabled value="x">-- Please Select A Project Site To Show EPC --</option>
              <option v-for="item in site_contractors" :key="item.site_contractor_id" v-bind:value="item.contractor.contractor_id">{{ item.contractor.contractor_code }} - {{ item.contractor.contractor_name }}</option>
            </select>
          </base-input>
        </b-col>
      </div>

      <div class="form-row">
        <b-col md="3">
          <base-input label="Project Status"
                      name="Project Status"
                      v-model="model.project_status">
            <select class="form-control" v-model="model.project_status">
              <option selected disabled :value=null>-- Please Select A Project Status --</option>
              <option value="ongoing">On Going</option>
              <option v-if="user_role != 12" value="completed">Completed</option>
            </select>
          </base-input>
        </b-col>

        <b-col md="3">
          <base-input label="Safety Inspection Type"
                      name="Safety Inspection Type"
                      v-model="model.safety_inspection_type_id">
            <select class="form-control" v-model="model.safety_inspection_type_id">
              <option selected disabled :value=null>-- Please Select A Safety Inspection Type --</option>
              <option v-for="item in safety_inspection_type" :key="item.safety_inspection_type_id" v-bind:value="item.safety_inspection_type_id">
                {{ item.safety_inspection_type_code }}
              </option>
            </select>
          </base-input>
        </b-col>
      </div>
      </card>

      <card>
      <div class="form-row">
        <b-col md="4">
          <base-input label="Date"
                      name="Date"
                      type="date"
                      v-model="model.safety_observation_create_date"
                      @change="validateDateTime">
          </base-input>
        </b-col>

        <b-col md="4">
          <base-input label="Time"
                      name="Time"
                      type="time"
                      v-model="model.safety_observation_create_time"
                      @change="validateDateTime">
          </base-input>
        </b-col>

        <b-col md="4">
          <base-input label="Hour(s)"
                      name="Hour(s)"
                      placeholder="1"
                      v-model="model.time_spent_hours" type="number" min="1" max="50">
          </base-input>
        </b-col>
      </div>
      <h5 class="text-danger" v-if="isFutureDate">Please enter a date and time in the past.</h5>

      <div class="form-row">
        <b-col md="12">
          <div class="form-group">
            <h5>
            Conducted By
            </h5>
            <div
              v-for="(input, index) in conducting_users"
              :key="`reviewerInput-${index}`">
              <div class="form-row">
                <b-col md="3">
                  <base-input v-model="input.userId">
                    <select
                      class="form-control"
                      v-if="index != 0"
                      v-model="input.userId"
                      @change="removeFromList($event)">
                      <option Selected disabled value="x">
                        -- Please Select --
                      </option>
                      <option
                        v-for="item in users"
                        :key="item.id"
                        v-bind:value="item.id"
                        :disabled="item.disabled"
                        v-show="!item.v_hide">
                        {{ item.name }} - {{ item.email }}
                      </option>
                    </select>
                    <select
                      class="form-control"
                      v-else
                      v-model="input.userId"
                      @change="removeFromList($event)"
                      disabled>
                      <option Selected disabled value="x">
                        -- Please Select --
                      </option>
                      <option
                        v-for="item in users"
                        :key="item.id"
                        v-bind:value="item.id"
                        :disabled="item.disabled"
                        v-show="!item.v_hide">
                        {{ item.name }} - {{ item.email }}
                      </option>
                    </select>
                  </base-input>
                </b-col>
                <b-col md="auto">
                  <base-button
                    size="sm"
                    type="primary"
                    @click="addField(input, conducting_users)">
                    Add
                  </base-button>
                </b-col>
                <b-col md="auto">
                  <base-button
                    size="sm"
                    type="primary"
                    @click="removeField(index, conducting_users)"
                    v-show="conducting_users.length > 1 && index != 0">
                    Remove
                  </base-button>
                </b-col>
              </div>
            </div>
          </div>
        </b-col>
      </div>
      </card>

      <card>
        <div>
          <h5>
            Observation Item
            <base-button style="float: right;" type="primary" @click="showNewObservationItemFromAdd()">
              Add New Item
            </base-button>
          </h5>
        </div>

        <b-table
          :items="model.safety_observation_items"
          :fields="fields">

          <template #cell(no.)="data">
            {{ data.index + 1 }}
          </template>

          <template #cell(actions)="row">
            <base-button type="primary" size="sm" @click="viewObservationItem(row.item, row.index, $event.target)" class="mr-1">
              View/Edit
            </base-button>
            <base-button type="danger" size="sm" @click="deleteObservationItem(row.item, row.index, $event.target)" class="mr-1">
              Delete
            </base-button>
          </template>

        </b-table>
      </card>

      <div>
        <div style="float: right;">
          <base-button type="danger" @click="$router.back()">Cancel</base-button>
          <base-button v-if="!submit_clicked" type="primary" @click="saveAsDraft()">Save As Draft</base-button>
          <base-button v-if="!submit_clicked" type="success" @click="submitData()">Submit</base-button>
          <base-button v-if="submit_clicked" type="primary" @click="saveAsDraft()" disabled>Save As Draft</base-button>
          <base-button v-if="submit_clicked" type="success" @click="submitData()" disabled>Submit</base-button>
        </div>
      </div>
    </form>
  </card>
  <!-- :no-close-on-backdrop="true" -->
  <modal
      :show.sync="isNewObservationItemVisible"
      size="xl"
      body-classes="p-0"
      title="New Observation Item"
    >
      <card
        type="secondary"
        header-classes="bg-transparent pb-5"
        body-classes="px-lg-5 py-lg-5"
        class="border-0 mb-0"
      >
        <template>
          <h1>New Observation Item</h1>
          <div style="float: right;">Status: {{getSafetyObservationStatus(new_model.safety_observation_status_id)}}</div>
          <br/>
          <card>
            <div class="form-row">
              <b-col md="12">
                <label for="textarea">Observation:</label>
                <b-form-textarea
                  id="textarea"
                  v-model="new_model.observation_description"
                  placeholder=""
                  rows="5"
                ></b-form-textarea>
              </b-col>
            </div>

            <div class="form-row">
              <b-col md="6">
                <base-input label="Type"
                            name="Type"
                            v-model="new_model.safety_observation_type_id">
                  <select class="form-control" v-model="new_model.safety_observation_type_id" @change="updateCorrectionPhotos()">
                    <option Selected disabled value="x">-- Please Select A Safety Observation Type --</option>
                    <option v-for="item in safety_observation_type" :key="item.safety_observation_type_id" v-bind:value="item.safety_observation_type_id">{{ item.safety_observation_type_code }}</option>
                  </select>
                </base-input>
              </b-col>

              <b-col md="6">
                <base-input label="Category"
                            name="Category"
                            v-model="new_model.safety_observation_category_id">
                  <select class="form-control" v-model="new_model.safety_observation_category_id">
                    <option Selected disabled value="x">-- Please Select A Safety Observation Category --</option>
                    <option v-for="item in safety_observation_category" :key="item.safety_observation_category_id" v-bind:value="item.safety_observation_category_id">{{ item.safety_observation_category }}</option>
                  </select>
                </base-input>
              </b-col>
            </div>

            <div class="form-row">
              <b-col md="6">
                <base-input label="Repeated Observation"
                  name="Repeated Observation"
                  v-model="model.repeated_observation">
                  <select class="form-control" v-model="new_model.repeated_observation">
                    <option value="0">No</option> 
                    <option value="1">Yes</option>
                  </select>
                </base-input>
              </b-col>
            </div>

            <div>
              <h5>
              Add Observation Photos
              </h5>
              <input
                v-show="false"
                type="file"
                id="imageFile"
                ref="imageFile"
                accept="image/jpeg, image/png, image/gif, image/webp"
                v-on:change="handleImageFileUpload()"
              />
              <div>
              <base-button v-if="new_model.safety_observation_photos.length <= 5" size="sm" type="primary" @click="$refs.imageFile.click()"
                >Add Photo</base-button
              >
              </div>
              <br/>
              <div v-for="(item, index) in new_model.safety_observation_photos" :key="index">
                <card>
                  <b-row align-v="center">
                  <b-col md="4">
                    <img
                      :src="item.photoPreviewSrc"
                      style="max-width:250px;max-height:250px;"
                      v-if="item.photoFile"
                    >
                  </b-col>

                  <b-col md="7">
                    <base-input
                      name="Observation Photo Remarks"
                      placeholder="Observation Photo Remarks"
                      v-model="item.photoRemarks"
                    >
                    </base-input>
                  </b-col>
                  <b-col md="1">
                    <base-button size="sm" type="primary" @click="deletePhoto(index)">
                      Delete
                    </base-button>
                  </b-col>
                  </b-row>
                </card>
              </div>
            </div>
            
            <div class="form-row">
              <b-col md="6">
                <base-input label="Need Correction Photos"
                            name="Need Correction Photos"
                            v-model="new_model.correction_photos_required">
                  <select class="form-control" v-model="new_model.correction_photos_required" v-if="new_model.safety_observation_type_id == 1 || new_model.safety_observation_type_id == 3" disabled>
                    <option Selected disabled value="x">-- Please Select A Value --</option>
                    <option value="1">Yes</option>
                    <option value="0">No</option>                    
                  </select>
                  <select class="form-control" v-model="new_model.correction_photos_required" v-else>
                    <option Selected disabled value="x">-- Please Select A Value --</option>
                    <option value="1">Yes</option>
                    <option value="0">No</option>                    
                  </select>
                </base-input>
              </b-col>

              <b-col md="6">
                <base-input label="Priority"
                            name="Priority"
                            v-model="new_model.safety_observation_priority_id">
                  <select class="form-control" v-model="new_model.safety_observation_priority_id" v-if="new_model.safety_observation_type_id == 1 || new_model.safety_observation_type_id == 3" disabled>
                    <option Selected disabled value="x">-- Please Select A Priority --</option>
                    <option Selected disabled value=null>-- None --</option>
                    <option v-for="item in safety_observation_priority" :key="item.safety_observation_priority_id" v-bind:value="item.safety_observation_priority_id">{{ item.safety_observation_priority_name }} - {{ item.safety_observation_priority_duration }} Days</option>
                  </select>
                  <select class="form-control" v-model="new_model.safety_observation_priority_id" v-else>
                    <option Selected disabled value="x">-- Please Select A Priority --</option>
                    <option v-for="item in safety_observation_priority" :key="item.safety_observation_priority_id" v-bind:value="item.safety_observation_priority_id">{{ item.safety_observation_priority_name }} - {{ item.safety_observation_priority_duration }} Days</option>
                  </select>
                </base-input>
              </b-col>
            </div>

            <div>
              <base-button
                type="primary"
                class="my-4"
                @click="closeNewObservationItem()"
                >Cancel</base-button
              >
              <base-button
                type="primary"
                class="my-4"
                @click="saveNewObservationItem()"
                >Save</base-button
              >
            </div>
          </card>
        </template>
      </card>
    </modal>

    <modal
      :show.sync="isSubmissionWarningVisible"
      :no-close-on-backdrop="true"
      size="xl"
      body-classes="p-0"
      title="Submission Warning"
    >
      <card>
        <template>
          <h1 class="text-center">Submission in Progress</h1>
          <div>
            Please be patient while we process your submission.<br>
            Do not navigate away from this page or refresh the page until the submission is complete.<br>
            You will be automatically redirected to another page once the process is finished.
          </div>
        </template>
      </card>
    </modal>

</div>
</template>
<script>
import BaseSlider from "@/components/BaseSlider";
import {
  LoginURL,
  clientId,
  clientSecret,
  apiDomain,
  getHeader,
  getcurrentDate,
} from "@/assets/js/config.js";
import axios from "axios";
import ErrorFunctionMixin from "@/assets/js/errorFunctionMix.js";
import jsonFormData from 'json-form-data';

import cloneDeep from 'lodash/cloneDeep';

export default {
  mixins: [ErrorFunctionMixin],
  components: {
    BaseSlider,
  },
  created() {
    var currentDate = getcurrentDate();
    this.model.safety_observation_create_date = currentDate;

    const today = new Date();
    this.model.safety_observation_create_time = today.getHours() + ":" + today.getMinutes();

    var userInfo = JSON.parse(window.localStorage.getItem("loginUser"));
    this.user_role = userInfo.role_id;
    this.user_id = userInfo.id;
    if (this.user_role == 12) {
      this.getContractorId();
    }

    this.getProjectListing();
    this.getUserList();
    this.getSafetyObservationTypes();
    this.getSafetyObservationCategories();
    this.getSafetyObservationPriorities();
    this.getSafetyInspectionTypes();

    axios
      .get(apiDomain + "lookup/safety_observation_status", { headers: getHeader() })
      .then((response) => {
        if (response.status === 200) {
          this.safety_observation_status = response.data.data;
        }
      })
      .catch((error) => {
        console.log(error.response.status);
        this.errorFunction(error, "Safety Observation Status");
      });

  },
  data() {
    return {
      user_role: null,
      user_id: null,
      contractor_id: null,
      isNewObservationItemVisible: false,
      developers: [],
      project_status: [],
      project_managers: [],
      project_engineer: [],
      project_safety: [],
      project_category: [],
      project_type: [],
      safety_inspection_type: [],
      task_templates: [],
      document_templates: [],
      response_model: {
        project_type_id: null,
        project_id: null,
        project_name: null,
        project_manager: null,
        project_progress: null,
        project_pv_capacity: null,
        status: null,
      },
      project_group_model: {
        project_id: "x",
        project_name: null,
        group_code: null,
        group_name: null,
        group_information: null,
        group_location: null,
        group_pv_capacity: "0.1",
        status_id: "x",
        group_engineer: "x",
        group_progress: 0,
      },
      model: {
        project_id: 'x',
        group_id: 'x',
        site_id: 'x',
        contractor_id: 'x',
        project_status: null,
        safety_inspection_type_id: null,
        safety_observation_create_date: null,
        safety_observation_create_time: null,
        time_spent_hours: 1,
        safety_observation_items: [],
        conducting_users: null,
        status: null,
      },
      projects: [],
      project_groups: [],
      project_sites: [],
      site_contractors: [],
      users: [],
      conducting_users: [],
      safety_observation_status: [],
      new_model: {
        observation_description: null,
        safety_observation_type_id: "x",
        safety_observation_category_id: "x",
        correction_photos_required: "x",
        safety_observation_priority_id: "x",
        safety_observation_photos: [
        ],
        safety_observation_status_id: 1,
        repeated_observation: 0,
      },
      safety_observation_type: [],
      safety_observation_category: [],
      safety_observation_priority: [],
      fields: [
        'no.',
        // Type
        {
          key: 'safety_observation_type_id',
          label: 'Type',
          formatter: (value, key, item) => {
            var findObj = this.safety_observation_type.find(obj => { return obj.safety_observation_type_id === value });
            if (typeof findObj !== 'undefined') {
              return findObj.safety_observation_type_code;
            } else {
              return value;
            }
          },
        },
        // Category
        {
          key: 'safety_observation_category_id',
          label: 'Category',
          formatter: (value, key, item) => {
            var findObj = this.safety_observation_category.find(obj => { return obj.safety_observation_category_id === value });
            if (typeof findObj !== 'undefined') {
              return findObj.safety_observation_category;
            } else {
              return value;
            }
          },
        },
        // Status
        {
          key: 'safety_observation_status_id',
          label: 'Status',
          formatter: (value, key, item) => {
            var findObj = this.safety_observation_status.find(obj => { return obj.status_id === value });
            if (typeof findObj !== 'undefined') {
              return findObj.status_code;
            } else {
              return value;
            }
          },
        },
        // Priority
        {
          key: 'safety_observation_priority_id',
          label: 'Priority',
          formatter: (value, key, item) => {
            var findObj = this.safety_observation_priority.find(obj => { return obj.safety_observation_priority_id === value });
            if (typeof findObj !== 'undefined') {
              return findObj.safety_observation_priority_name;
            } else {
              return value;
            }
          },
        },
        // Actions
        { key: 'actions', label: 'Actions' },
        ],
      safety_observation_item_mode: 'create',
      current_item_index: null,
      submit_clicked: false,
      isFutureDate: false,
      isSubmissionWarningVisible: false,
    };
  },
  methods: {
    updateCorrectionPhotos(){
      // IF POSITIVE OR GOOD PRACTICE, DO NOT REQUIRE CORRECTION PHOTOS, SET PRIORITY TO NULL
      if (this.new_model.safety_observation_type_id == 1 || this.new_model.safety_observation_type_id == 3) {
        this.new_model.correction_photos_required = 0;
        this.new_model.safety_observation_priority_id = null;
      } else {
        this.new_model.safety_observation_priority_id = "x";
      }
    },
    viewObservationItem(item, index, button) {
      // this.new_model = this.model.safety_observation_items[index];
      // this.new_model = {...this.model.safety_observation_items[index]};
      // this.new_model = Object.assign({}, this.model.safety_observation_items[index]);
      // this.new_model = JSON.parse(JSON.stringify(this.model.safety_observation_items[index]));
      this.new_model = cloneDeep(this.model.safety_observation_items[index]);
      this.safety_observation_item_mode = 'edit';
      this.current_item_index = index;
      this.showNewObservationItem();
    },
    deleteObservationItem(item, index, button) {
      this.model.safety_observation_items.splice(index, 1);
    },
    getSafetyObservationStatus(status_id) {
      var findObj = this.safety_observation_status.find(obj => { return obj.status_id == status_id });
      if (typeof findObj !== 'undefined') {
        return findObj.status_code;
      } else {
        return status_id;
      }
    },
    removeFromList(event) {
      this.conducting_users.map((e_rev) => {
        if (e_rev.userId == event.target.value) {
          if (e_rev.previousSelection != "") {
            this.users.map((e_rol) => {
              if (e_rol.id == e_rev.previousSelection) {
                e_rol["disabled"] = false;
                e_rol["v_hide"] = false;
              }
            });
          }
        }
        this.users.map((e_rol) => {
          if (e_rol.id == event.target.value) {
            e_rol["disabled"] = true;
            e_rol["v_hide"] = true;
          }
        });
        e_rev.previousSelection = e_rev.userId;
      });
    },
    addField(object, fieldType) {
      let newObject = {};
      for (const [key, value] of Object.entries(object)) {
        newObject[key] = "";
      }
      fieldType.push(newObject);
    },
    removeField(index, fieldType) {
      if (fieldType[index].userId != "") {
        this.users.map((e_rol) => {
          if (e_rol.id == fieldType[index].userId) {
            e_rol["disabled"] = false;
            e_rol["v_hide"] = false;
          }
        });
      }
      fieldType.splice(index, 1);
    },
    showNewObservationItem() {
      this.isNewObservationItemVisible = true;
    },
    showNewObservationItemFromAdd() {
      this.safety_observation_item_mode = 'create';
      this.new_model = {
        observation_description: null,
        safety_observation_type_id: "x",
        safety_observation_category_id: "x",
        correction_photos_required: "x",
        safety_observation_priority_id: "x",
        safety_observation_photos: [
        ],
        safety_observation_status_id: 1,
        repeated_observation: 0,
      },
      this.isNewObservationItemVisible = true;
    },
    closeNewObservationItem() {
      this.new_model = {
        observation_description: null,
        safety_observation_type_id: "x",
        safety_observation_category_id: "x",
        correction_photos_required: "x",
        safety_observation_priority_id: "x",
        safety_observation_photos: [
        ],
        safety_observation_status_id: 1,
        repeated_observation: 0,
      },
      this.isNewObservationItemVisible = false;
    },
    saveNewObservationItem() {
      if (
        !this.new_model.observation_description ||
        this.new_model.safety_observation_type_id == "x" ||
        this.new_model.safety_observation_category_id == "x" ||
        this.new_model.correction_photos_required == "x" ||
        this.new_model.safety_observation_priority_id == "x"
      ) {
        if (!this.new_model.observation_description) {
          this.redErrorNotify(
            "<b>Invalid Input : Observation Remarks is a Mandatory Field in Safety Observation </b>"
          );
        } else if (this.new_model.safety_observation_type_id == "x") {
          this.redErrorNotify(
            "<b>Invalid Input : Safety Observation Type is a Mandatory Field in Safety Observation </b>"
          );
        } else if (this.new_model.safety_observation_category_id == "x") {
          this.redErrorNotify(
            "<b>Invalid Input : Safety Observation Category is a Mandatory Field in Safety Observation </b>"
          );
        } else if (this.new_model.correction_photos_required == "x") {
          this.redErrorNotify(
            "<b>Invalid Input : Need Correction Photos is a Mandatory Field in Safety Observation </b>"
          );
        } else if (this.new_model.safety_observation_priority_id == "x") {
          this.redErrorNotify(
            "<b>Invalid Input : Safety Observation Priority is a Mandatory Field in Safety Observation </b>"
          );
        } else {
          this.redErrorNotify(
            "<b>Invalid Input : Please fill in all the Mandatory Fields in Safety Observation </b>"
          );
        }
      } else {
        if (this.safety_observation_item_mode == 'create') {
          this.model.safety_observation_items.push(this.new_model);
        } else if (this.safety_observation_item_mode == 'edit') {
          this.model.safety_observation_items[this.current_item_index] = this.new_model;          
          this.current_item_index = null;
        }
        
        this.new_model = {
          observation_description: null,
          safety_observation_type_id: "x",
          safety_observation_category_id: "x",
          correction_photos_required: "x",
          safety_observation_priority_id: "x",
          safety_observation_photos: [
          ],
          safety_observation_status_id: 1,
          repeated_observation: 0,
        },
        this.safety_observation_item_mode = 'create';
        this.isNewObservationItemVisible = false;
      }
    },
    getProjectListing(){
      if (this.user_role == 12) {
        if (this.contractor_id != null) {
          this.getProjectListingContractor();
        }
      } else {
        this.getProjectListingNormal();
      }
    },
    getProjectListingNormal()
    {
      axios.get(apiDomain + 'project/project.orderByName', { headers: getHeader() })
      .then ( response => {
      if (response.status === 200)
      {
          this.projects = response.data.data;
      }
      })
      .catch ( error => {
      console.log(error.response.status)
      this.errorFunction(error, "Project Listing")
      });
    },
    getProjectListingContractor()
    {
      axios.get(apiDomain + 'project/project.show_by_contractor/'  + this.contractor_id, { headers: getHeader() })
      .then ( response => {
      if (response.status === 200)
      {
          this.projects = response.data.data;
      }
      })
      .catch ( error => {
      console.log(error.response.status)
      this.errorFunction(error, "Project Listing Contractor")
      });
    },
    getProjectStatus()
    {
      // IF input_site_type = 'ongoing' THEN
      //   SET site_status = '1,2,3,4,6';
      // ELSEIF input_site_type = 'completed' THEN
      //   SET site_status = '5,7';
      // END IF;
      const project = this.projects.find(item => item.project_id === this.model.project_id);
      if ( [1, 2, 3, 4, 6].includes(project.status.status_id) ) {
        this.model.project_status = 'ongoing';
      } else if ( [5, 7].includes(project.status.status_id) ) {
        this.model.project_status = 'completed';
      } else {
        this.model.project_status = null;
      }
    },
    getGroupFromProject(){
      if (this.user_role == 12) {
        this.getGroupFromProjectContractor();
      } else {
        this.getGroupFromProjectNormal();
      }
    },
    getGroupFromProjectNormal()
    {
      axios.get(apiDomain + 'project/project_group.show_by_project/' + this.model.project_id , { headers: getHeader() })
      .then ( response => {
        if (response.status === 200)
        {
          this.project_groups = response.data.data;
          this.model.group_id = 'y';
          this.model.site_id = 'x';
          this.project_sites = [];
        }
      })
      .catch ( error => {
        console.log(error.response.status);
      } );
    },
    getGroupFromProjectContractor()
    {
      axios.get(apiDomain + 'project/project_group.show_by_project_by_contractor/' + this.model.project_id + '/' + this.contractor_id, { headers: getHeader() })
      .then ( response => {
        if (response.status === 200)
        {
          this.project_groups = response.data.data;
          this.model.group_id = 'y';
          this.model.site_id = 'x';
          this.project_sites = [];
        }
        })
      .catch ( error => {
      console.log(error.response.status)
      });
    },
    getSiteFromGroup(){
      if (this.user_role == 12) {
        this.getSiteFromGroupContractor();
      } else {
        this.getSiteFromGroupNormal();
      }
    },
    getSiteFromGroupNormal()
    {
      axios.get(apiDomain + 'project/project_site.show_by_group/' + this.model.group_id , { headers: getHeader() })
      .then ( response => {
        if (response.status === 200)
        {
          this.project_sites = response.data.data;
          this.model.site_id = 'y';
        }
      })
      .catch ( error => {
        console.log(error.response.status);
      } );
    },
    getSiteFromGroupContractor()
    {
      axios.get(apiDomain + 'project/project_site.show_by_group_by_contractor/' + this.model.group_id + '/' + this.contractor_id, { headers: getHeader() })
      .then ( response => {
        if (response.status === 200)
        {
          this.project_sites = response.data.data;
          this.model.site_id = 'y';
        }
        })
      .catch ( error => {
      console.log(error.response.status)
      });
    },
    getContractorFromSite()
    {
      axios.get(apiDomain + 'project/site_contractor.show_by_site/' + this.model.site_id , { headers: getHeader() })
      .then ( response => {
        if (response.status === 200)
        {
          this.site_contractors = response.data.data;
          var index = this.site_contractors.findIndex(x => x.active_status ==="ACTIVE");
          this.model.contractor_id = this.site_contractors[index].contractor.contractor_id;
        }
      })
      .catch ( error => {
        console.log(error.response.status)
        this.errorFunction(error, "Site Contractor Information")
      } );
    },
    addUserToConducting(){
      this.conducting_users.push({ userId: this.user_id, previousSelection: this.user_id });
      var tempUserId = this.user_id;
      tempUserId = tempUserId.toString();
      var tempEvent = {
        target: {
          value: tempUserId
        }
      };
      this.removeFromList(tempEvent);
    },
    getUserList(){
      if (this.user_role == 12) {
        if (this.contractor_id != null) {
          this.getUserListContractor();
        }
      } else {
        this.getUserListNormal();
      }
    },
    getUserListNormal()
    {
      axios.get(apiDomain + 'lookup/user_list', { headers: getHeader() })
        .then ( response => {
        if (response.status === 200)
        {
          this.users = response.data.data;
          this.addUserToConducting();
        }
        })
        .catch ( error => {
          this.errorFunction(error, 'User Listing')
        });
    },
    getUserListContractor()
    {
      axios.get(apiDomain + 'lookup/user_list.contractor/' + this.contractor_id, { headers: getHeader() })
        .then ( response => {
        if (response.status === 200)
        {
          this.users = response.data.data;
          this.addUserToConducting();
        }
        })
        .catch ( error => {
          this.errorFunction(error, 'User Listing')
        });
    },
    getSafetyObservationTypes()
    {
      axios.get(apiDomain + 'lookup/safety_observation_type', { headers: getHeader() })
      .then ( response => {
        if (response.status === 200)
        {
          this.safety_observation_type = response.data.data;
        }
      })
      .catch ( error => {
        console.log(error.response.status)
        this.errorFunction(error, "Safety Observation Type Information")
      } );
    },
    // getSafetyInspectionTypes
    getSafetyInspectionTypes()
    {
      axios.get(apiDomain + 'lookup/safety_inspection_type', { headers: getHeader() })
      .then ( response => {
        if (response.status === 200)
        {
          this.safety_inspection_type = response.data.data;
        }
      })
      .catch ( error => {
        console.log(error.response.status)
        this.errorFunction(error, "Safety Inspection Type Information")
      } );
    },
    getSafetyObservationCategories()
    {
      axios
      .get(apiDomain + "setup/safety_observation_category_active", { headers: getHeader() })
      .then((response) => {
        if (response.status === 200) {
          this.safety_observation_category = response.data.data;
        }
      })
      .catch((error) => {
        console.log(error.response.status);
        this.errorFunction(error, "Safety Observation Category");
      });
    },
    getSafetyObservationPriorities()
    {
      axios
      .get(apiDomain + "setup/safety_observation_priority_active", { headers: getHeader() })
      .then((response) => {
        if (response.status === 200) {
          this.safety_observation_priority = response.data.data;
        }
      })
      .catch((error) => {
        console.log(error.response.status);
        this.errorFunction(error, "Safety Observation Priority");
      });
    },

    handleImageFileUpload() {
      let vm = this;
      this.new_model.safety_observation_photos.push(
        {
          photoFile: {},
          photoPreviewSrc: "",
          photoRemarks: "",
        },
      );
      console.log(this.$refs.imageFile.files[0]);
      this.new_model.safety_observation_photos.at(-1).photoFile = this.$refs.imageFile.files[0];
      console.log(this.new_model.safety_observation_photos.at(-1).photoFile);

      var reader = new FileReader();
      reader.onload = function(e) {
        vm.new_model.safety_observation_photos.at(-1).photoPreviewSrc = e.target.result;
      };

      reader.readAsDataURL(this.new_model.safety_observation_photos.at(-1).photoFile);
    },
    deletePhoto(index){
      this.new_model.safety_observation_photos.splice(index, 1);
    },
    checkAndCreateConductingUsers() {
      let tempConductingUsers = [];
      this.conducting_users.forEach((item) => {
        if (item.userId != "") {
          tempConductingUsers.push({ userId: item.userId });
        }
      });
      this.model.conducting_users = tempConductingUsers;
    },
    checkForRequiredData() {
      this.checkAndCreateConductingUsers();
      this.validateDateTime();
      var checker = false;
      if (
        this.model.project_id == 'x' ||
        this.model.group_id == 'x' ||
        this.model.site_id == 'x' ||
        this.model.contractor_id == 'x' ||
        !this.model.project_status ||
        !this.model.safety_inspection_type_id ||
        !this.model.safety_observation_create_date ||
        !this.model.safety_observation_create_time ||
        this.model.safety_observation_items.length < 1 ||
        this.isFutureDate
      ) {
        if (this.model.project_id == 'x') {
          this.redErrorNotify(
            "<b>Invalid Input : Project is a Mandatory Field in Safety Observation </b>"
          );
        } else if (this.model.group_id == 'x') {
          this.redErrorNotify(
            "<b>Invalid Input : Project Group is a Mandatory Field in Safety Observation </b>"
          );          
        } else if (this.model.site_id == 'x') {
          this.redErrorNotify(
            "<b>Invalid Input : Project Site is a Mandatory Field in Safety Observation </b>"
          );          
        } else if (this.model.contractor_id == 'x') {
          this.redErrorNotify(
            "<b>Invalid Input : EPC (Project Site Contractor) is a Mandatory Field in Safety Observation </b>"
          );          
        } else if (!this.model.project_status) {
          this.redErrorNotify(
            "<b>Invalid Input : Project Status is a Mandatory Field in Safety Observation </b>"
          );          
        } else if (!this.model.safety_inspection_type_id) {
          this.redErrorNotify(
            "<b>Invalid Input : Safety Inspection Type is a Mandatory Field in Safety Observation </b>"
          );
        } else if (!this.model.safety_observation_create_date) {
          this.redErrorNotify(
            "<b>Invalid Input : Date is a Mandatory Field in Safety Observation </b>"
          );          
        } else if (!this.model.safety_observation_create_time) {
          this.redErrorNotify(
            "<b>Invalid Input : Time is a Mandatory Field in Safety Observation </b>"
          );          
        } else if (this.model.safety_observation_items.length < 1) {
          this.redErrorNotify(
            "<b>Invalid Input : Safety Observation Item is a Mandatory Field in Safety Observation </b>"
          );
        } else if (this.isFutureDate) {
          this.redErrorNotify(
            "<b>Invalid Input : Date and Time cannot be in the future. </b>"
          );
        } else {
          this.redErrorNotify(
            "<b>Invalid Input : Please fill in all the Mandatory Fields in Safety Observation </b>"
          );
        }        
      } else {
        console.log('passed all checks');
        checker = true;
        this.submit_clicked = true;
        this.isSubmissionWarningVisible = true;
      }
      return checker;
    },
    saveAsDraft(){
      if (this.checkForRequiredData()) {
        console.log('save as draft');

        this.model.status = 0;

        let formData = jsonFormData(this.model);

        const tokenData = JSON.parse(window.localStorage.getItem("authUser"));
        const headers = {
          Authorization: "Bearer " + tokenData.access_token,
        };

        axios
          .post(
            apiDomain + "health_safety/safety_observation",
            formData,
            { headers }
          )
          .then((response) => {
            if (response.status === 200) {
              this.$notify({
              message:
                '<b>Safety Observation Successfully Created and Saved As Draft.</b>',
              timeout: 10000,
              icon: 'ni ni-bell-55',
              type: 'default',
              });
              this.$router.back()
            }
          })
          .catch((error) => {
            if (error.response) {
              // Handle error with response
              this.errorFunction(error, "Safety Observation Save As Draft");
            } else {
              // Handle error without response
              console.log(error)
              this.$notify({
                message:
                // '<b>System Warning : </b> - The System is Currently Busy or Un-Available.',
                '<b>System Warning : </b> - There is an error with your submission. Please contact Sembcorp staff you know or email to sembsolar.momens@sembcorp.com',
                timeout: 15000,
                icon: 'ni ni-bulb-61',
                type: 'warning',
              });
            }
            this.submit_clicked = false;
            this.isSubmissionWarningVisible = false;
          });
        }
    },
    submitData(){
      if (this.checkForRequiredData()) {
        console.log('submit data');

        this.model.status = 1;

        let formData = jsonFormData(this.model);

        const tokenData = JSON.parse(window.localStorage.getItem("authUser"));
        const headers = {
          Authorization: "Bearer " + tokenData.access_token,
        };

        axios
          .post(
            apiDomain + "health_safety/safety_observation",
            formData,
            { headers }
          )
          .then((response) => {
            if (response.status === 200) {
              this.$notify({
              message:
                '<b>Safety Observation Successfully Created and Submitted.</b>',
              timeout: 10000,
              icon: 'ni ni-bell-55',
              type: 'default',
              });
              this.$router.back()
            }
          })
          .catch((error) => {
            if (error.response) {
              // Handle error with response
              this.errorFunction(error, "Safety Observation Submit");
            } else {
              // Handle error without response
              console.log(error)
              this.$notify({
                message:
                // '<b>System Warning : </b> - The System is Currently Busy or Un-Available.',
                '<b>System Warning : </b> - There is an error with your submission. Please contact Sembcorp staff you know or email to sembsolar.momens@sembcorp.com',
                timeout: 15000,
                icon: 'ni ni-bulb-61',
                type: 'warning',
              });
            }
            this.submit_clicked = false;
            this.isSubmissionWarningVisible = false;
          });
        }
    },
    validateDateTime() {
      const currentDate = new Date();
      const enteredDate = new Date(`${this.model.safety_observation_create_date}T${this.model.safety_observation_create_time}`);
      this.isFutureDate = enteredDate > currentDate;
    },
    getContractorId(){
      axios.get(apiDomain + 'lookup/contractor.contractor_id/' + this.user_id, { headers: getHeader() })
        .then ( response => {
        if (response.status === 200)
        {
          this.contractor_id = response.data.contractor_id;
          this.getProjectListing();
          if (this.users.length == 0) {
            this.getUserListContractor();
          }          
        }
        })
        .catch ( error => {
          this.errorFunction(error, 'Contractor Id')
        });
    },
  },
};
</script>
<style>
.form-control {
  color: black;
}
.form-row {
  color: black;
}
select option {
  color: black;
}
textarea {
  color: black;
}
</style>
