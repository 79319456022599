<template>
    <section style="margin-left:15px;">
        <hr style="margin-top: 5px; margin-bottom: 5px; border-top: 2px solid black;">
        <div class="row">
            <div class="col-md-6 text-start">
                <p class="form-content font-type">Site Code:{{model.site_code}}</p>
            </div>
            <div class="col-md-6 text-right">
                <p class="form-content font-type">{{model.site_address}}</p>
            </div>
        </div>

        <div class="row">
            <div class="col-md-4">
                <div class="card card-border-inner-v2 bg-transparent form-custom-css card-custom-height-v2">
                    <div class="card-body pt-0">
                        <b-row>
                            <div class="form-group col-md-6">
                                <p class='form-label font-type'>Roof Handover Date</p>
                                <p class='form-content font-type'>{{model.handover_date}}</p>
                            </div>

                            <div class="form-group col-md-6 text-right">
                                <p class='form-label font-type'>COD</p>
                                <p class='form-content font-type'>{{model.site_cod_date}}</p>
                            </div>
                        </b-row>
                    </div>
                </div>

            </div>

            <div class="col-md-4">
                <div class="card card-border-inner-v2 bg-transparent form-custom-css card-custom-height-v2">
                    <div class="card-body pt-0">
                        <b-row>
                            <div class="form-group col-md-6">
                                <p class='form-label font-type'>Planned Turn On</p>
                                <p class='form-content font-type'>{{model.site_target_turn_on_date}}</p>
                            </div>

                            <div class="form-group col-md-6 text-right">
                                <p class='form-label font-type'>Actual Turn On</p>
                                <p class='form-content font-type'>{{model.site_actual_turn_on_date}}</p>
                            </div>
                        </b-row>
                    </div>
                </div>
            </div>

            <div class="col-md-4">
                <div class="card card-border-inner-v2 bg-transparent form-custom-css card-custom-height-v2">
                    <div class="card-body pt-0">
                        <b-row>
                            <div class="form-group col-md-12">
                                <p class='form-label text-start font-type'>As Built Capacity</p>
                                <h1 class='font-weight-bold text-center font-type' style="font-size:30px;">{{model.as_built_capacity}}</h1>
                            </div>
                        </b-row>
                    </div>
                </div>
            </div>
        </div>

        <div id="gantt-chart" v-show="site_milestones.length>0" class="mb-5">
            <!--<card class="bg-transparent form-custom-css">-->
                <div id="chart" style="border: 1px solid #375623;">
                    <apexchart type="rangeBar" height="350" :options="chartOptions" :series="series"></apexchart>
                </div>
            <!--</card>-->
        </div>

        <div v-show="site_milestones.length>0">
            <b-row>
                <b-col md="2" >
                    <h2 class="text-left mt-3 text-muted" style="font-weight:700!important;">milestones</h2>
                    <hr style="margin-top: 5px; margin-bottom: 5px; border-top: 2px solid black;">
                    <div v-for="item in site_milestones" class="mt-10" style="padding-top:4px;">
                        <div class="bg-transparent p-0 mt-2" style="height:62px;">
                            <p class="form-label text-right" style="margin-top:30px;">{{item.site_milestone_code}}</p>
                        </div>

                    </div>
                </b-col>
                <b-col md="5">
                    <div class="card card-border-diagonal-v2 p-0" style="border: 1px solid black; background-color:#F2F2F2;">
                        <div class="card-body">
                            <h2 class="text-right" style="font-weight:1000 !important; color:#24537D;">planned</h2>
                            <!-- card for planned date -->
                            <b-row v-for="item in site_milestones">
                                <div class="card card-border-radius-custom p-0 card-background-color" >
                                    <b-row md="12">
                                        <div class="card-body">
                                            <div  v-show="item.planned_start_date !==null && item.planned_end_date !==null" class="">
                                                <b-row>
                                                    <div class="col-md-9">
                                                        <b-row>
                                                            <div class="col-md-5">
                                                                <span class="text-start font-custom-rem">{{item.planned_start_date}}</span>
                                                            </div>
                                                            <div class="col-md-2">
                                                                <span><svg xmlns="http://www.w3.org/2000/svg"  width="20" height="20"><path style="fill:#2E75B5" d="M10.029 5H0v7.967h10.029V18l9.961-9.048L10.029 0v5z"/></svg></span>
                                                            </div>
                                                            <div class="col-md-5">
                                                                <span class="text-start font-custom-rem">{{item.planned_end_date}}</span>
                                                            </div>
                                                        </b-row>
                                                    </div>
                                                    <div class="col-md-3" style="margin-top:-13px;">
                                                        <span class="text-muted font-weight-bold font-custom-css" style="font-weight:800 !important; font-size:30px;">{{getDaysDifference(item.planned_start_date,item.planned_end_date)}}</span>
                                                        <span class="text-muted" style="font-size:12px;"> Days</span>
                                                    </div>
                                                </b-row>
                                            </div>
                                        </div>
                                    </b-row>
                                </div>
                            </b-row>
                        </div>
                    </div>
                </b-col>
                <b-col md="5">
                    <div class="card card-border-diagonal-v2 p-0" style="border: 1px solid black; background-color:#F2F2F2;">
                        <div class="card-body">
                            <h2 class="text-right" style="font-weight:1000 !important; color:#3B5928;">actual</h2>
                            <!-- card for planned date -->
                            <b-row v-for="item in site_milestones">
                                <div class="card card-border-radius-custom p-0 card-background-color-v2">
                                    <b-row md="12">
                                        <div class="card-body" style="min-height:50%;">
                                            <div v-show="item.actual_start_date !==null && item.actual_end_date !==null" class="">
                                                <b-row>
                                                    <div class="col-md-9">
                                                        <b-row>
                                                            <div class="col-md-5">
                                                                <span class="text-start ml-2 mr-2 font-custom-rem" :style="item.color">{{item.actual_start_date}}</span>
                                                            </div>
                                                            <div class="col-md-2" style="">
                                                                <span><svg xmlns="http://www.w3.org/2000/svg"  width="20" height="20"><path :style="item.fill" d="M10.029 5H0v7.967h10.029V18l9.961-9.048L10.029 0v5z"/></svg></span>
                                                            </div>
                                                            <div class="col-md-5" style="">
                                                                <span class="text-start font-custom-rem" :style="item.color">{{item.actual_end_date}}</span>
                                                            </div>
                                                        </b-row>
                                                    </div>
                                                    <div class="col-md-3" style="margin-top:-13px;">
                                                        <span class="font-weight-bold font-custom-css " :style="item.color" style="font-weight:800 !important; font-size:30px;">{{getDaysDifference(item.actual_start_date,item.actual_end_date)}}</span>
                                                        <span class="" style="font-size:12px;" :style="item.color"> Days</span>
                                                    </div>
                                                </b-row>

                                            </div>
                                        </div>
                                    </b-row>
                                </div>
                            </b-row>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </div>
        <div>
            <b-row>
                <b-col md="3">
                    <div class="card card-border-inner-v2 bg-transparent form-custom-css card-custom-height-v3">
                        <div class="card-body pt-0">
                                <div class="form-group">
                                    <p class='font-style-custom-css form-label-v2 text-muted p-line-space-title'>ntp applicable</p>
                                </div>

                                <div class="form-group text-right">
                                    <p class="p-line-space">
                                        <span class="form-content">{{additional_model.ntp_issue_date}}</span><br>
                                        <span class="text-muted font-11">NTP Issued Date</span>
                                    </p>
                                </div>
                        </div>
                    </div>
                </b-col>

                <b-col md="3">
                    <div class="card card-border-inner-v2 bg-transparent form-custom-css card-custom-height-v3">
                        <div class="card-body pt-0">
                            <div class="form-group">
                                <p class='form-label-v2 text-muted font-style-custom-css p-line-space-title'>internal project handover</p>
                            </div>

                            <div class="form-group text-right">
                                <!--<p class='form-content'>{{additional_model.internal_project_handover_date}}</p>-->
                                <!--<p class='form-label text-muted'>Internal Project Handover Date</p>-->
                                <p class="p-line-space-v2">
                                    <span class="form-content">{{additional_model.internal_project_handover_date}}</span><br>
                                    <span class="text-muted font-11">Internal Project Handover Date</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </b-col>

                <b-col md="3">
                    <div class="card card-border-inner-v2 bg-transparent form-custom-css card-custom-height-v3">
                        <div class="card-body pt-0">
                            <div class="form-group">
                                <p class='form-label-v2 text-muted font-style-custom-css p-line-space-title'>kick off meeting with client</p>
                            </div>

                            <div class="form-group text-right">
                                <p class="p-line-space-v2">
                                    <span class="form-content">{{additional_model.kick_off_meeting_date}}</span><br>
                                    <span class="text-muted font-11">Kick Off Meeting with Client Date</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </b-col>

                <b-col md="3">
                    <div class="card card-border-inner-v2 bg-transparent form-custom-css card-custom-height-v3">
                        <div class="card-body pt-0">
                            <div class="form-group">
                                <p class='form-label-v2 text-muted font-style-custom-css p-line-space-title'>roof handover</p>
                            </div>

                            <div class="form-group text-right">
                                <p class="p-line-space">
                                    <span class="form-content">{{additional_model.roof_handover_date}}</span><br>
                                    <span class="text-muted font-11">Roof Handover Date</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </b-col>
            </b-row>

            <b-row>
                <b-col md="6">
                    <div class="card card-border-inner-custom bg-transparent form-custom-css ">
                        <div class="card-body pt-0">
                            <div class="form-group">
                                <p class='form-label-v2 text-muted card-title-custom'>module allocation</p>
                            </div>

                            <div class="form-group row">
                                    <div class="col-md-6">
                                        <p class='form-label text-muted'>Brand : <span class="form-content">{{model.site_module_brand_id_name}}</span></p>
                                        <p class='form-label text-muted'>Watt Class : <span class="form-content">{{model.site_module_capacity}}</span></p>
                                        <p class='form-label text-muted'>Remarks :
                                            <base-button
                                                size="sm"
                                                @click.native="openModal(model.site_ifc_ifa_module_remarks)"
                                                class="remarks-button-css"
                                             >
                                             View Remarks
                                            </base-button>
                                        </p>
                                    </div>

                                    <div class="col-md-6">
                                        <p class='form-label text-muted'>No. of module : <span class="form-content">{{model.site_module_quantity}}</span></p>
                                        <p class='form-label text-muted'>Capacity : <span class="form-content">{{model.site_module_allocation_total_capacity}} kWp</span></p>
                                    </div>
                            </div>
                        </div>
                    </div>
                </b-col>

                <b-col md="6">
                    <div class="card card-border-inner-custom bg-transparent form-custom-css ">
                        <div class="card-body pt-0">
                            <div class="form-group">
                                <p class='form-label-v2 text-muted card-title-custom'>drawing</p>
                            </div>

                            <div class="form-group row mt-0 pt-0">
                                <div class="col-md-6">
                                    <p class='form-label text-muted'>Brand : <span class="form-content">{{model.site_drawing_module_brand_id_name}}</span></p>
                                    <p class='form-label text-muted'>Watt Class : <span class="form-content">{{model.site_drawing_module_capacity}}</span></p>
                                    <p class='form-label text-muted'>IFA date : <span class="form-content">{{model.site_drawing_ifa_date}}</span></p>
                                </div>

                                <div class="col-md-6">
                                    <p class='form-label text-muted'>No. of module : <span class="form-content">{{model.site_drawing_module_quantity}}</span></p>
                                    <p class='form-label text-muted'>Capacity : <span class="form-content">{{model.site_drawing_total_capacity}} kWp</span></p>
                                    <p class='form-label text-muted'>IFC date : <span class="form-content">{{model.site_drawing_ifc_date}}</span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </b-col>
            </b-row>

            <b-row>
                <b-col md="12">
                    <h1 class="text-muted">Turn On Checking</h1>
                </b-col>

                <b-col md="4">
                    <div class="card card-border-inner-custom bg-transparent form-custom-css">
                         <div class="card-body pt-0">
                             <div class="form-group">
                                 <p class='form-label-v2 text-muted card-title-custom'>COPS/T&C</p>
                             </div>

                             <div  v-if="additional_model.cops_tnc_checked == 1" class="form-group text-center">
                                 <i class="ni ni-check-bold" style="font-size: 30px;"></i>
                             </div>
                             <div v-else style="margin-top:80px;">
                             </div>
                             <div class="form-group">
                                 <p class='form-label text-muted'>Any Issue : <span class="form-content">{{additional_model.cops_tnc_issue_desc}}</span></p>
                                 <p class='form-label text-muted'>Remarks : <base-button
                                         size="sm"
                                         class="remarks-button-css"
                                         @click.native="openModal(additional_model.cops_tnc_remarks)"
                                 >
                                     View Remarks
                                 </base-button></p>
                             </div>
                         </div>
                    </div>
                </b-col>

                <b-col md="4">
                    <div class="card card-border-inner-custom bg-transparent form-custom-css ">
                        <div class="card-body pt-0">
                            <div class="form-group">
                                <p class='form-label-v2 text-muted card-title-custom'>O&M Handover Doc</p>
                            </div>

                            <div v-if="additional_model.onm_handover_checked == 1" class="form-group text-center">
                                <i class="ni ni-check-bold" style="font-size: 30px;"></i>
                            </div>
                            <div v-else style="margin-top:80px;">
                            </div>
                            <div class="form-group">
                                <p class='form-label text-muted'>Any Issue : <span class="form-content">{{additional_model.onm_handover_issue_desc}}</span></p>
                                <p class='form-label text-muted'>Remarks : <base-button
                                        size="sm"
                                        class="remarks-button-css"
                                        @click.native="openModal(additional_model.onm_handover_remarks)"
                                >
                                    View Remarks
                                </base-button></p>
                            </div>
                        </div>
                    </div>
                </b-col>

                <b-col md="4">
                    <div class="card card-border-inner-custom bg-transparent form-custom-css ">
                        <div class="card-body pt-0">
                            <div class="form-group">
                                <p class='form-label-v2 text-muted card-title-custom'>Approval to close</p>
                            </div>

                            <div v-if="additional_model.turn_on_checking_approved == 1" class="form-group text-center">
                                <i class="ni ni-check-bold" style="font-size:30px;"></i>
                            </div>
                            <div v-else style="margin-top:80px;">
                            </div>
                            <div class="form-group">
                                <p class='form-label text-muted'>Approved By : <span class="form-content">{{additional_model.turn_on_checking_approver_name}}</span></p>
                                <p class='form-label text-muted'>Approved Date : <span class="form-content">{{additional_model.turn_on_checking_approval_date}}</span></p>
                            </div>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </div>

        <modal :show.sync="modals.remarks"
               size="lg"
               body-classes="p-0">
            <card type="secondary"
                  header-classes="bg-transparent pb-5"
                  body-classes="px-lg-5 py-lg-5"
                  class="border-0 mb-0">
                <template>
                    <div class="text-center text-muted mb-4">
                        <h3>Remarks</h3>
                    </div>
                    <b-form role="form">
                        <b-form-textarea
                                id="textarea"
                                v-model="model.modal_remark_content"
                                placeholder="Remarks"
                                rows="10"
                                readonly
                        ></b-form-textarea>
                        <div class="text-center">
                            <base-button class="my-4 remarks-button-css" @click="clearModal()">Close</base-button>
                        </div>
                    </b-form>
                </template>
            </card>
        </modal>
    </section>
</template>
<script>
import {
    LoginURL,
    clientId,
    clientSecret,
    apiDomain,
    getHeader,
    getcurrentDate,} from '@/assets/js/config.js';
import axios from 'axios';
import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js';
import jsonFormData from 'json-form-data';
import VueApexCharts from "vue-apexcharts";
import moment from "moment";
export default {
    mixins: [ErrorFunctionMixin],
    components: {
        apexchart: VueApexCharts,
    },
    props: ['siteId'],
    created() {
    },
    data() {
        return {
            model: {
                site_id: null,
                site_code: "N/A",
                site_address: "x",
                as_built_capacity: null,
                site_cod_date: null,
                roof_handover_date:null,
                site_target_turn_on_date:null,
                site_actual_turn_on_date: null,
                site_total_capacity: null, // CHECK WITH ANDREW
                site_drawing_module_brand_id: 'x',
                site_drawing_module_brand_id_name: 'x',
                site_drawing_module_quantity: 0,
                site_drawing_module_capacity: 0,
                site_drawing_total_capacity: 0,
                site_drawing_ifa_date: null,
                site_drawing_ifc_date: null,
                site_module_brand_id: 'x',
                site_module_brand_id_name : 'x',
                site_module_quantity: 0,
                site_module_capacity: 0,
                site_module_allocation_total_capacity: 0,
                site_ifc_ifa_module_remarks: null,
                milestone_template_id: 'x',
                modal_remark_content : null,
            },
            modals: {
                remarks: false,
            },
            additional_model: {
                project_site_additional_info_id: null,
                site_id: null,
                ntp_applicable: 0,
                ntp_issued: 0,
                ntp_issue_date: null,
                internal_project_handover_completed: 0,
                internal_project_handover_date: null,
                kick_off_meeting_completed: 0,
                kick_off_meeting_date: null,
                roof_handover_completed: 0,
                roof_handover_date: null,
                cops_tnc_checked: 0,
                cops_tnc_issue: 0,
                cops_tnc_issue_desc : 'x',
                cops_tnc_remarks: null,
                onm_handover_checked: 0,
                onm_handover_issue: 0,
                onm_handover_issue_desc: 'x',
                onm_handover_remarks: null,
                turn_on_checking_approved: 0,
                turn_on_checking_approval_date: null,
                turn_on_checking_approver: null,
                turn_on_checking_approver_name: null,
            },
            site_milestones : [],
            series: [
                {
                    name: 'Planned',
                    data: [
                        {
                            x: 'Application to Authorities',
                            y: [
                                new Date('2019-03-05').getTime(),
                                new Date('2019-03-08').getTime()
                            ]
                        },
                        {
                            x: 'Mobilization to Site',
                            y: [
                                new Date('2019-03-08').getTime(),
                                new Date('2019-03-11').getTime()
                            ]
                        },
                        {
                            x: 'Hoisting',
                            y: [
                                new Date('2019-03-11').getTime(),
                                new Date('2019-03-16').getTime()
                            ]
                        }
                    ]
                },
                {
                    name: 'Actual',
                    data: [
                        {
                            x: 'Application to Authorities',
                            y: [
                                new Date('2019-03-02').getTime(),
                                new Date('2019-03-05').getTime()
                            ]
                        },
                        {
                            x: 'Mobilization to Site',
                            y: [
                                new Date('2019-03-06').getTime(),
                                new Date('2019-03-09').getTime()
                            ]
                        },
                        {
                            x: 'Hoisting',
                            y: [
                                new Date('2019-03-10').getTime(),
                                new Date('2019-03-19').getTime()
                            ]
                        }
                    ]
                }
            ],
            chartOptions: {
                chart: {
                    height: 350,
                    type: 'rangeBar',
                    background: "#DDEED2",
                    zoom:{
                        enabled:false
                    }
                },
                colors: [
                    "#2E75B5",
                    ({ value, dataPointIndex }) => {
                        if (this.site_milestones.length > 0 ) {
                            if (this.site_milestones[dataPointIndex].planned_end_date) {
                                const plannedEndDate = new Date(this.site_milestones[dataPointIndex].planned_end_date).getTime();
                                if (value > plannedEndDate) {
                                    return '#FF0000'
                                } else {
                                    return '#00B050'
                                }
                            } else {
                                return '#00B050'
                            }
                        } else {
                            return '#00B050'
                        }
                    }
                ],
                plotOptions: {
                    bar: {
                        horizontal: true,
                    }
                },
                dataLabels: {},
                fill: {},
                xaxis: {
                    type: 'datetime',
                },
                legend: {
                    position: 'top',
                    markers:{
                        fillColors: ['#2E7585','#00B050']
                    }
                }
            },
        };
    },
    isSiteMilestoneVisible: false,
    selectedDataPointIndex: 0,
    contacts_model: {
        project_manager: null,
        project_engineer: null,
        project_business_development: null,
        project_safety: null,
    },
    projects_model: {
        project_ppa_start_date: null,
        project_ppa_approved_capacity: null,
    },
    watch: {
        siteId:{
            immediate: true,
            handler(value){
                this.getInfo(value);
            }
        },
    },
    methods: {
        async getInfo(value) {
            try {
                await this.getSiteInfo(value);
                await this.getSiteAdditionalData(value);
                await this.getProjectSiteMilestoneData(value);
                await this.getDashboardAdditionalInfo(value);
            }
            catch (error) {
                this.errorFunction(error, "Site Information");
            }
        },
        getSiteInfo(site_id){
            return axios
                .get(apiDomain + "project/project_site/" + site_id, {
                    headers: getHeader(),
                })
                .then((response) => {
                    if (response.status === 200) {

                        this.model.site_id = 'Site Code :  '+response.data.data[0].site_code;
                        this.model.site_address = response.data.data[0].site_address+"-"+response.data.data[0].site_postal_code;
                        let this_site_target_turn_on_date = (response.data.data[0].site_target_turn_on_date !== null) ? response.data.data[0].site_target_turn_on_date : "N/A";
                        if(this_site_target_turn_on_date !== "N/A"){
                            let convertSiteTurnOnDate = new Date(this_site_target_turn_on_date);
                            const year = convertSiteTurnOnDate.getFullYear();
                            const month = convertSiteTurnOnDate.toLocaleString('en-US', { month: 'short' });
                            const day = convertSiteTurnOnDate.toLocaleString('en-US',{day:'2-digit'});
                            this_site_target_turn_on_date = `${day}-${month}-${year}`;
                        }
                        this.model.site_target_turn_on_date = this_site_target_turn_on_date;
                        let site_actual_turn_on_date = (response.data.data[0].site_actual_turn_on_date !== null) ? response.data.data[0].site_actual_turn_on_date : "N/A";
                        if(site_actual_turn_on_date !== "N/A"){
                            let convertSiteActualTurnOnDate = new Date(site_actual_turn_on_date);
                            const year = convertSiteActualTurnOnDate.getFullYear();
                            const month = convertSiteActualTurnOnDate.toLocaleString('en-US', { month: 'short' });
                            const day = convertSiteActualTurnOnDate.toLocaleString('en-US',{day:'2-digit'});
                            site_actual_turn_on_date = `${day}-${month}-${year}`;
                        }
                        this.model.site_actual_turn_on_date = site_actual_turn_on_date;
                        this.model.as_built_capacity = parseFloat(response.data.data[0].site_total_capacity.replace(/,/g, ""))+" kWp";

                        let this_site_cod_date = (response.data.data[0].site_cod_date !== null) ? response.data.data[0].site_cod_date :'N/A';
                        console.log(this_site_cod_date);
                        if(this_site_cod_date !== "N/A"){
                            let convertCodDate = new Date(this_site_cod_date);
                            const year = convertCodDate.getFullYear();
                            const month = convertCodDate.toLocaleString('en-US', { month: 'short' });
                            const day = convertCodDate.toLocaleString('en-US',{day:'2-digit'});
                            this_site_cod_date = `${day}-${month}-${year}`;
                        }
                        this.model.site_cod_date = this_site_cod_date;

                        this.model.site_total_capacity = response.data.data[0].site_total_capacity;

                        this.model.site_drawing_module_brand_id = response.data.data[0].site_drawing_module_brand.module_brand_id;
                        this.model.site_drawing_module_brand_id_name = (response.data.data[0].site_drawing_module_brand.module_brand_id_name !== null) ? response.data.data[0].site_drawing_module_brand.module_brand_id_name : "N/A";
                        this.model.site_drawing_module_quantity = parseFloat(response.data.data[0].site_drawing_module_quantity.replace(/,/g, ""));
                        this.model.site_drawing_module_capacity = parseFloat(response.data.data[0].site_drawing_module_capacity.replace(/,/g, ""));
                        this.model.site_drawing_total_capacity = parseFloat(response.data.data[0].site_drawing_total_capacity.replace(/,/g, ""));
                        let this_site_drawing_ifa_date = (response.data.data[0].site_drawing_ifa_date !== null) ? response.data.data[0].site_drawing_ifa_date : "N/A";
                        if(this_site_drawing_ifa_date !== "N/A"){
                            let convertIFADate = new Date(this_site_drawing_ifa_date);
                            const year = convertIFADate.getFullYear();
                            const month = convertIFADate.toLocaleString('en-US', { month: 'short' });
                            const day = convertIFADate.toLocaleString('en-US',{day:'2-digit'});
                            this_site_drawing_ifa_date = `${day}-${month}-${year}`;
                        }
                        this.model.site_drawing_ifa_date = this_site_drawing_ifa_date;
                        let this_site_drawing_ifc_date = (response.data.data[0].site_drawing_ifc_date !== null) ? response.data.data[0].site_drawing_ifc_date : "N/A";
                        if(this_site_drawing_ifc_date !== "N/A"){
                            let convertIFCDate = new Date(this_site_drawing_ifc_date);
                            const year = convertIFCDate.getFullYear();
                            const month = convertIFCDate.toLocaleString('en-US', { month: 'short' });
                            const day = convertIFCDate.toLocaleString('en-US',{day:'2-digit'});
                            this_site_drawing_ifc_date = `${day}-${month}-${year}`;
                        }
                        this.model.site_drawing_ifc_date =  this_site_drawing_ifc_date;
                        this.model.site_module_brand_id = response.data.data[0].site_module_brand.module_brand_id;
                        this.model.site_module_brand_id_name = (response.data.data[0].site_module_brand.module_brand_id_name) ? response.data.data[0].site_module_brand.module_brand_id_name : "N/A";
                        this.model.site_module_quantity = parseFloat(response.data.data[0].site_module_quantity.replace(/,/g, ""));
                        this.model.site_module_capacity = parseFloat(response.data.data[0].site_module_capacity.replace(/,/g, ""));
                        this.model.site_module_allocation_total_capacity = parseFloat(response.data.data[0].site_module_allocation_total_capacity.replace(/,/g, ""));;
                        this.model.site_ifc_ifa_module_remarks = response.data.data[0].site_ifc_ifa_module_remarks;
                    }
                })
        },
        getSiteAdditionalData(site_id){
            return axios
                .get(apiDomain + "project/project_site_additional_info/" + site_id, {
                    headers: getHeader(),
                })
                .then((response) => {
                    if (response.status === 200) {
                        let this_handover_date = (response.data.data[0].internal_project_handover_date !== null) ? response.data.data[0].internal_project_handover_date : "N/A";
                        if(this_handover_date !== 'N/A'){
                            let convertDate = new Date(this_handover_date);
                            const year = convertDate.getFullYear();
                            const month = convertDate.toLocaleString('en-US', { month: 'short' });
                            const day = convertDate.toLocaleString('en-US',{day:'2-digit'});
                            this_handover_date = `${day}-${month}-${year}`;
                        }

                        this.model.handover_date = this_handover_date;
                    }
                })
        },
        getProjectSiteMilestoneData(site_id) {
            return axios
                .get(
                    apiDomain +
                    "project/project_site_milestone.show_by_site/" +
                    site_id,
                    { headers: getHeader() }
                )
                .then((response) => {
                    if (response.status === 200) {
                        let data = response.data.data;
                        data.forEach(item => {
                            if (item.planned_end_date) {
                                const plannedEndDate = new Date(item.planned_end_date).getTime();
                                const actualEndDate = new Date(item.actual_end_date).getTime();
                                if (actualEndDate > plannedEndDate) {
                                    item.color = 'color:#FF0000';
                                    item.fill = 'fill:#FF0000';
                                } else {
                                    item.color = 'color:#00B050';
                                    item.fill = 'fill:#00B050';
                                }
                            } else {
                                item.color = 'color:#00B050';
                                item.fill = 'fill:#00B050';
                            }

                            const convertPlannedEndDate = (item.planned_end_date !== null ) ? new Date(item.planned_end_date) : null;
                            if(convertPlannedEndDate !== null){
                                const year = convertPlannedEndDate.getFullYear();
                                const month = convertPlannedEndDate.toLocaleString('en-US', { month: 'short' });
                                const day = convertPlannedEndDate.toLocaleString('en-US',{day:'2-digit'});
                                item.planned_end_date = `${day}-${month}-${year}`;
                            }

                            const convertPlannedStartDate = (item.planned_start_date !== null) ? new Date(item.planned_start_date) : null;
                            if(convertPlannedStartDate !== null){
                                const year = convertPlannedStartDate.getFullYear();
                                const month = convertPlannedStartDate.toLocaleString('en-US', { month: 'short' });
                                const day = convertPlannedStartDate.toLocaleString('en-US',{day:'2-digit'});
                                item.planned_start_date = `${day}-${month}-${year}`;
                            }

                            const convertActualStartDate = (item.actual_start_date !== null ) ? new Date(item.actual_start_date) : null;
                            if(convertActualStartDate !== null){
                                const year = convertActualStartDate.getFullYear();
                                const month = convertActualStartDate.toLocaleString('en-US', { month: 'short' });
                                const day = convertActualStartDate.toLocaleString('en-US',{day:'2-digit'});
                                item.actual_start_date = `${day}-${month}-${year}`;
                            }

                            const convertActualEndDate = (item.actual_end_date !== null ) ? new Date(item.actual_end_date) : null;
                            if(convertActualEndDate !== null){
                                const year = convertActualEndDate.getFullYear();
                                const month = convertActualEndDate.toLocaleString('en-US', { month: 'short' });
                                const day = convertActualEndDate.toLocaleString('en-US',{day:'2-digit'});
                                item.actual_end_date = `${day}-${month}-${year}`;
                            }


                        });
                        this.site_milestones = data;
                        console.log(this.site_milestones);
                        this.updateGanttChartSeries();

                        // get and update Planned Turn On (site_target_turn_on_date) based on "Turn On" planned_end_date
                        // get and update Actual Turn On based on "Turn On" actual_end_date if value is null
                        // ONE WAY!!!
                        // changing planned_end_date of "Turn On" will change site_target_turn_on_date
                        // changing site_target_turn_on_date will not change planned_end_date of "Turn On"

                        const turn_on_milestone_data = this.site_milestones.find(obj => obj.site_milestone_code === "Turn On");

                        if (turn_on_milestone_data != undefined) {
                            // update Planned Turn On (site_target_turn_on_date) based on "Turn On" planned_end_date
                            if (turn_on_milestone_data.planned_end_date) {
                                let this_site_target_turn_on_date = (turn_on_milestone_data.planned_end_date !== null) ? turn_on_milestone_data.planned_end_date : "N/A";
                                if(this_site_target_turn_on_date !== "N/A"){
                                    let convertSiteTurnOnDate = new Date(this_site_target_turn_on_date);
                                    const year = convertSiteTurnOnDate.getFullYear();
                                    const month = convertSiteTurnOnDate.toLocaleString('en-US', { month: 'short' });
                                    const day = convertSiteTurnOnDate.toLocaleString('en-US',{day:'2-digit'});
                                    this_site_target_turn_on_date = `${day}-${month}-${year}`;
                                }
                                this.model.site_target_turn_on_date = this_site_target_turn_on_date;
                            }
                            // update Actual Turn On based on "Turn On" actual_end_date if value is null
                            if (turn_on_milestone_data.actual_end_date && (this.model.site_actual_turn_on_date == null)) {
                                let this_site_actual_turn_on_date = (turn_on_milestone_data.actual_end_date !== null) ? turn_on_milestone_data.actual_end_date : "N/A";
                                if(this_site_actual_turn_on_date !== "N/A"){
                                    let convertSiteActualTurnOnDate = new Date(this_site_actual_turn_on_date);
                                    const year = convertSiteActualTurnOnDate.getFullYear();
                                    const month = convertSiteActualTurnOnDate.toLocaleString('en-US', { month: 'short' });
                                    const day = convertSiteActualTurnOnDate.toLocaleString('en-US',{day:'2-digit'});
                                    this_site_actual_turn_on_date = `${day}-${month}-${year}`;
                                }

                                if (turn_on_milestone_data.planned_end_date) {
                                    this.model.site_actual_turn_on_date = this_site_target_turn_on_date;
                                }
                                this.model.site_actual_turn_on_date = this_site_actual_turn_on_date;
                            }
                        }
                    }
                })
        },
        updateGanttChartSeries() {
            const temp_series = [
                {
                    name: 'Planned',
                    data: this.site_milestones.map(item => ({
                        x: item.site_milestone_code,
                        y: [
                            item.planned_start_date ? new Date(item.planned_start_date).getTime() : null,
                            item.planned_end_date ? new Date(item.planned_end_date).getTime() : null
                        ]
                    }))
                },
                {
                    name: 'Actual',
                    data: this.site_milestones.map(item => ({
                        x: item.site_milestone_code,
                        y: [
                            item.actual_start_date ? new Date(item.actual_start_date).getTime() : null,
                            item.actual_end_date ? new Date(item.actual_end_date).getTime() : null
                        ]
                    }))
                }
            ];

            this.series = [];
            this.series = temp_series;

            // this.chartOptions.xaxis.min = new Date('2023-07-01').getTime();
            // this.chartOptions.xaxis.max = new Date('2023-12-01').getTime();
        },
        getDaysDifference(startDate, endDate) {

            if(startDate != "N/A" || endDate != "N/A"){
                // Convert the date strings to Date objects
                const startDateObj = new Date(startDate);
                const endDateObj = new Date(endDate);

                // Calculate the difference in milliseconds between the two dates
                const differenceInMilliseconds = endDateObj - startDateObj;

                // Convert milliseconds to days
                const millisecondsInOneDay = 1000 * 60 * 60 * 24;
                const differenceInDays = differenceInMilliseconds / millisecondsInOneDay;

                // Round the difference to remove decimal places
                return Math.round(differenceInDays);
            }else{
                return 0;
            }
        },
        getDashboardAdditionalInfo(site_id) {
            axios
                .get(apiDomain + "project/project_site_additional_info/" + site_id, {
                    headers: getHeader(),
                })
                .then((response) => {
                    if (response.status === 200) {
                        this.additional_model.project_site_additional_info_id = response.data.data[0].project_site_additional_info_id;
                        this.additional_model.site_id = response.data.data[0].site_id;
                        this.additional_model.ntp_applicable = response.data.data[0].ntp_applicable;
                        this.additional_model.ntp_issued = response.data.data[0].ntp_issued;
                        let this_ntp_issue_date = (response.data.data[0].ntp_issue_date !==null) ? response.data.data[0].ntp_issue_date : "N/A";
                        if(this_ntp_issue_date !== "N/A"){
                            let convertNtpIssueDate = new Date(this_ntp_issue_date);
                            const year = convertNtpIssueDate.getFullYear();
                            const month = convertNtpIssueDate.toLocaleString('en-US', { month: 'short' });
                            const day = convertNtpIssueDate.toLocaleString('en-US',{day:'2-digit'});
                            this_ntp_issue_date = `${day}-${month}-${year}`;
                        }
                        this.additional_model.ntp_issue_date = this_ntp_issue_date;
                        this.additional_model.internal_project_handover_completed = response.data.data[0].internal_project_handover_completed;
                        let this_internal_project_handover_date = (response.data.data[0].internal_project_handover_date !==null) ? response.data.data[0].internal_project_handover_date : "N/A";
                        if(this_internal_project_handover_date !== "N/A"){
                            let convertInternalProjectHandoverDate = new Date(this_internal_project_handover_date);
                            const year = convertInternalProjectHandoverDate.getFullYear();
                            const month = convertInternalProjectHandoverDate.toLocaleString('en-US', { month: 'short' });
                            const day = convertInternalProjectHandoverDate.toLocaleString('en-US',{day:'2-digit'});
                            this_internal_project_handover_date = `${day}-${month}-${year}`;
                        }
                        this.additional_model.internal_project_handover_date = this_internal_project_handover_date;
                        this.additional_model.kick_off_meeting_completed = response.data.data[0].kick_off_meeting_completed;
                        let this_kick_off_meeting_date = (response.data.data[0].kick_off_meeting_date !==null) ? response.data.data[0].kick_off_meeting_date : "N/A";
                        if(this_kick_off_meeting_date !== "N/A"){
                            let convertKickOffMeetingDate = new Date(this_kick_off_meeting_date);
                            const year = convertKickOffMeetingDate.getFullYear();
                            const month = convertKickOffMeetingDate.toLocaleString('en-US', { month: 'short' });
                            const day = convertKickOffMeetingDate.toLocaleString('en-US',{day:'2-digit'});
                            this_kick_off_meeting_date = `${day}-${month}-${year}`;
                        }
                        this.additional_model.kick_off_meeting_date = this_kick_off_meeting_date;
                        this.additional_model.roof_handover_completed = response.data.data[0].roof_handover_completed;
                        let this_roof_handover_date = (response.data.data[0].roof_handover_date !== null) ? response.data.data[0].roof_handover_date : "N/A";
                        if(this_roof_handover_date !== "N/A"){
                            let convertRoofHandoverDate = new Date(this_roof_handover_date);
                            const year = convertRoofHandoverDate.getFullYear();
                            const month = convertRoofHandoverDate.toLocaleString('en-US', { month: 'short' });
                            const day = convertRoofHandoverDate.toLocaleString('en-US',{day:'2-digit'});
                            this_roof_handover_date = `${day}-${month}-${year}`;
                        }
                        this.additional_model.roof_handover_date = this_roof_handover_date;

                        this.additional_model.cops_tnc_checked = response.data.data[0].cops_tnc_checked;
                        this.additional_model.cops_tnc_issue = response.data.data[0].cops_tnc_issue;
                        this.additional_model.cops_tnc_issue_desc = (response.data.data[0].cops_tnc_issue == 1) ? "Yes" : "No" ;
                        this.additional_model.cops_tnc_remarks = response.data.data[0].cops_tnc_remarks;
                        this.additional_model.onm_handover_checked = response.data.data[0].onm_handover_checked;
                        this.additional_model.onm_handover_issue = response.data.data[0].onm_handover_issue;
                        this.additional_model.onm_handover_issue_desc = (response.data.data[0].onm_handover_issue == 1) ? "Yes" : "No" ;
                        this.additional_model.onm_handover_remarks = response.data.data[0].onm_handover_remarks;
                        this.additional_model.turn_on_checking_approved = response.data.data[0].turn_on_checking_approved;
                        let this_turn_on_checking_approval_date = (response.data.data[0].turn_on_checking_approval_date !== null) ? response.data.data[0].turn_on_checking_approval_date : "N/A";
                        if(this_turn_on_checking_approval_date !== "N/A"){
                            let convertTurnOnCheckingApprovalDate = new Date(this_turn_on_checking_approval_date);
                            const year = convertTurnOnCheckingApprovalDate.getFullYear();
                            const month = convertTurnOnCheckingApprovalDate.toLocaleString('en-US', { month: 'short' });
                            const day = convertTurnOnCheckingApprovalDate.toLocaleString('en-US',{day:'2-digit'});
                            this_turn_on_checking_approval_date = `${day}-${month}-${year}`;
                        }
                        this.additional_model.turn_on_checking_approval_date = this_turn_on_checking_approval_date;
                        this.additional_model.turn_on_checking_approver = response.data.data[0].turn_on_checking_approver;
                        this.additional_model.turn_on_checking_approver_name = (response.data.data[0].turn_on_checking_approver !== null) ? response.data.data[0].turn_on_checking_approver.name : "N/A";

                    }
                })
                .catch((error) => {
                    this.errorFunction(error, "Site Additional Information");
                });
        },
        openModal(remarks) {
            console.log(remarks);
            this.model.modal_remark_content = remarks;
            console.log(this.model.modal_remark_content);
            this.modals.remarks = true;
        },
        clearModal() {
            this.model.modal_remark_content = null;
            this.modals.remarks = false;
        },
    }
};
</script>
<style>
.card-custom-height-v2 {
    max-height:80px !important;
}

.card-custom-height-v3 {
    max-height:100px !important;
}

.card-border-inner-custom {
    border: 1px solid #ADB5BD;
    border-radius: 50px 50px 50px 50px;
    /* margin: 20px; */
    padding: 10px;
    /* max-width: 300px; */
    max-height: 200px;
    min-height:200px;
}

.card-border-inner-v2{
    border: 1px solid #ADB5BD;
    border-radius: 25px 25px 25px 25px;
    /* margin: 20px; */
    padding: 10px;
    /* max-width: 300px; */
    max-height: 200px;
    padding-top : 0rem !important;
    padding-left: 0rem !important;
    padding-right: 0rem !important;
}

.card-border-radius-custom{
    /* border: 2px solid #BDDBA9; */
    border-radius: 100px;
    /* margin: 20px; */
    /*padding: 10px;*/
    /* max-width: 300px; */
    /*height:50px;*/
    margin-bottom:20px !important;
    border: 1px solid black;
    width:100%;
    /*min-height:75px;*/
    /*max-height:75px;*/
    height:75px;
}

.card-border-diagonal-v2{
    /* border: 2px solid #BDDBA9; */
    border-radius: 25px 0 25px 0;
    /* margin: 20px; */
    padding: 10px;
    /* max-width: 300px; */
}

.card-background-color{
    background-color: #DEEBF6 !important;
    border: 2px solid #C4D9EB !important;
}

.card-background-color-v2{
    background-color: #EFF7EA !important;
    border: 2px solid #D7E9CA !important;
}

.font-custom-css{
    margin-left:20px;
    /*font-weight: 100 !important;*/
}

.font-type{
    font-family: "Calibri", sans-serif;
}

.form-label-v2{
    color: #777777 !important;
    font-weight:700 !important;
    font-size:12px !important;
}

.font-11{
    font-size:11px !important;
    color: #777777 !important;
    font-weight:600 !important;
}

.p-line-space{
    line-height:1em;
    margin-top:35px;
}

.card-title-custom{
    font-size: 20px !important;
    font-weight: 700 !important;
}

.remarks-button-css {
    background-color: #375623;
}

.font-style-custom-css{
    font-size:15px !important;
}

.font-custom-rem{
    font-size:0.95rem !important;
}

.p-line-space-title{
    line-height:1em;
    margin-top:10px;
}

.p-line-space-v2{
    line-height:1em;
    margin-top:-5px;
}
@media(max-width: 800px) {
    .p-line-space-title{
        line-height:1em;
        margin-top:10px;
    }
}

@media(max-width: 1080px){
    .p-line-space-v2{
        line-height:1em;
        margin-top:35px;
    }
}

@media(min-width: 2000px){
    .p-line-space-v2{
        line-height:1em;
        margin-top:35px;
    }
}

@media(min-width: 1280px){
    .p-line-space-v2{
        line-height:1em;
        /*margin-top:-5px;*/
    }
}

/*@media(min-width: 1368px){*/
    /*.p-line-space-v2{*/
        /*!*line-height:1em !important;*!*/
        /*margin-top:25px !important;*/
    /*}*/
/*}*/
</style>
