<template>
  <card>
    <!-- <div>
      safetyObservationResponse:<br/>
      <pre>
        {{safetyObservationResponse}}
      </pre>
      model:<br/>
      <pre>
        {{model}}
      </pre>
      new_model:<br/>
      <pre>
        {{new_model}}
      </pre>
    </div> -->

    <card>
      <div class="form-row">
        <b-col md="3">
          <base-input label="Project"
                      name="Project"
                      v-model="model.project_id">
            <!-- <select class="form-control" v-model="model.project_id" @change="getGroupFromProject()" disabled> -->
            <select class="form-control" v-model="model.project_id" disabled>
              <option Selected disabled value="x">-- Please Select A Project --</option>
              <option v-for="item in projects" :key="item.project_id" v-bind:value="item.project_id">{{ item.project_name }}</option>
            </select>
          </base-input>
        </b-col>

        <b-col md="3">
          <base-input label="Project Group"
                      name="Project Group"
                      v-model="model.group_id">
            <!-- <select class="form-control" v-model="model.group_id" @change="getSiteFromGroup()" disabled> -->
            <select class="form-control" v-model="model.group_id" disabled>
              <option Selected disabled value="x">-- Please Select A Project To Show Group --</option>
              <option Selected disabled value="y">-- Please Select A Group --</option>
              <option v-for="item in project_groups" :key="item.group_id" v-bind:value="item.group_id">{{ item.group_name }}</option>
            </select>
          </base-input>
        </b-col>

        <b-col md="3">
          <base-input label="Project Site"
                      name="Project Site"
                      v-model="model.site_id">
            <!-- <select class="form-control" v-model="model.site_id" @change="getContractorFromSite()" disabled> -->
            <select class="form-control" v-model="model.site_id" disabled>
              <option Selected disabled value="x">-- Please Select A Project Group To Show Project Site --</option>
              <option Selected disabled value="y">-- Please Select A Project Site --</option>
              <option v-for="item in project_sites" :key="item.site_id" v-bind:value="item.site_id">{{ item.site_name }}</option>
            </select>
          </base-input>
        </b-col>

        <b-col md="3">
          <base-input label="EPC"
                      name="EPC"
                      v-model="model.contractor_id">
            <select class="form-control" v-model="model.contractor_id" disabled>
              <option Selected disabled value="x">-- Please Select A Project Site To Show EPC --</option>
              <option v-for="item in site_contractors" :key="item.site_contractor_id" v-bind:value="item.contractor.contractor_id">{{ item.contractor.contractor_code }} - {{ item.contractor.contractor_name }}</option>
            </select>
          </base-input>
        </b-col>
      </div>

      <div class="form-row">
        <b-col md="3">
          <base-input label="Project Status"
                      name="Project Status"
                      v-model="model.project_status">
            <select class="form-control" v-model="model.project_status" disabled>
              <option selected disabled :value=null>-- Please Select A Project Status --</option>
              <option value="ongoing">On Going</option>
              <option value="completed">Completed</option>
            </select>
          </base-input>
        </b-col>

        <b-col md="3">
          <base-input label="Safety Inspection Type"
                      name="Safety Inspection Type"
                      v-model="model.safety_inspection_type_id">
            <select class="form-control" v-model="model.safety_inspection_type_id" disabled>
              <option selected disabled :value=null>-- Please Select A Safety Inspection Type --</option>
              <option v-for="item in safety_inspection_type" :key="item.safety_inspection_type_id" v-bind:value="item.safety_inspection_type_id">
                {{ item.safety_inspection_type_code }}
              </option>
            </select>
          </base-input>
        </b-col>
      </div>
    </card>

    <card>
      <div class="form-row">
        <b-col md="4">
          <base-input label="Date"
                      name="Date"
                      type="date"
                      v-model="model.safety_observation_create_date" disabled>
          </base-input>
        </b-col>

        <b-col md="4">
          <base-input label="Time"
                      name="Time"
                      type="time"
                      v-model="model.safety_observation_create_time" disabled>
          </base-input>
        </b-col>

        <b-col md="4">
          <base-input label="Hour(s)"
                      name="Hour(s)"
                      placeholder="1"
                      v-model="model.time_spent_hours" type="number" min="1" max="50" disabled>
          </base-input>
        </b-col>
      </div>

      <div class="form-row">
        <b-col md="12">
          <div class="form-group">
            <h5>
            Conducted By
            </h5>
            <div
              v-for="(input, index) in conducting_users"
              :key="`reviewerInput-${index}`">
              <div class="form-row">
                <b-col md="3">
                  <base-input v-model="input.userId">
                    <select
                      class="form-control"
                      v-if="index != 0"
                      v-model="input.userId"
                      @change="removeFromList($event)"
                      disabled>
                      <option Selected disabled value="x">
                        -- Please Select --
                      </option>
                      <option
                        v-for="item in users"
                        :key="item.id"
                        v-bind:value="item.id"
                        :disabled="item.disabled"
                        v-show="!item.v_hide">
                        {{ item.name }} - {{ item.email }}
                      </option>
                    </select>
                    <select
                      class="form-control"
                      v-else
                      v-model="input.userId"
                      @change="removeFromList($event)"
                      disabled>
                      <option Selected disabled value="x">
                        -- Please Select --
                      </option>
                      <option
                        v-for="item in users"
                        :key="item.id"
                        v-bind:value="item.id"
                        :disabled="item.disabled"
                        v-show="!item.v_hide">
                        {{ item.name }} - {{ item.email }}
                      </option>
                    </select>
                  </base-input>
                </b-col>
                <!-- <b-col md="auto">
                  <base-button
                    size="sm"
                    type="primary"
                    @click="addField(input, conducting_users)">
                    Add
                  </base-button>
                </b-col> -->
                <!-- <b-col md="auto">
                  <base-button
                    size="sm"
                    type="primary"
                    @click="removeField(index, conducting_users)"
                    v-show="conducting_users.length > 1 && index != 0">
                    Remove
                  </base-button>
                </b-col> -->
              </div>
            </div>
          </div>
        </b-col>
      </div>
    </card>

    <card>
      <div>
        <h5>
          Observation Item
          <!-- <base-button style="float: right;" type="primary" @click="showNewObservationItem()">
            Add New Item
          </base-button> -->
        </h5>
      </div>

      <b-table
      :items="model.safety_observation_items"
      :fields="fields"
      responsive>
        <template #cell(no.)="data">
          {{ data.index + 1 }}
        </template>

        <template #cell(actions)="row">
          <base-button type="primary" size="sm" @click="viewObservationItem(row.item, row.index, $event.target)" class="mr-1">
            View/Edit
          </base-button>
          <!-- <base-button type="danger" size="sm" @click="deleteObservationItem(row.item, row.index, $event.target)" class="mr-1">
            Delete
          </base-button> -->
        </template>
      </b-table>
    </card>

    <!-- <div>
      <div style="float: right;">
        <base-button type="primary" @click="$router.back()">Cancel</base-button>
        <base-button v-if="model.status == 0" type="primary" @click="saveAsDraft()">Save As Draft</base-button>
        <base-button v-if="model.status == 0" type="primary" @click="submitData()">Submit</base-button>
      </div>
    </div> -->

    <modal
    :show.sync="isNewObservationItemVisible"
    size="xl"
    body-classes="p-0"
    title="New Observation Item">
      <card
      type="secondary"
      header-classes="bg-transparent pb-5"
      body-classes="px-lg-5 py-lg-5"
      class="border-0 mb-0">
        <template>
          <!-- EDIT TITLE BASED ON STATUS EITHER NEW OR EDIT -->
          <h1>New Observation Item</h1>
          <div style="float: right;">Status: {{getSafetyObservationStatus(new_model.safety_observation_status_id)}}</div>
          <br/>
          <card>
            <div class="form-row">
              <b-col md="12">
                <label for="textarea">Observation:</label>
                <b-form-textarea
                  id="textarea"
                  v-model="new_model.observation_description"
                  placeholder=""
                  rows="5"
                ></b-form-textarea>
              </b-col>
            </div>

            <div class="form-row">
              <b-col md="6">
                <base-input label="Type"
                            name="Type"
                            v-model="new_model.safety_observation_type_id">
                  <select class="form-control" v-model="new_model.safety_observation_type_id">
                    <option Selected disabled value="x">-- Please Select A Safety Observation Type --</option>
                    <option v-for="item in safety_observation_type" :key="item.safety_observation_type_id" v-bind:value="item.safety_observation_type_id">{{ item.safety_observation_type_code }}</option>
                  </select>
                </base-input>
              </b-col>

              <b-col md="6">
                <base-input label="Category"
                            name="Category"
                            v-model="new_model.safety_observation_category_id">
                  <select class="form-control" v-model="new_model.safety_observation_category_id">
                    <option Selected disabled value="x">-- Please Select A Safety Observation Category --</option>
                    <option v-for="item in safety_observation_category" :key="item.safety_observation_category_id" v-bind:value="item.safety_observation_category_id">{{ item.safety_observation_category }}</option>
                  </select>
                </base-input>
              </b-col>
            </div>

            <div>
              <h5>Add Observation Photos</h5>

              <input
                v-show="false"
                type="file"
                id="imageFile"
                ref="imageFile"
                accept="image/jpeg, image/png, image/gif, image/webp"
                v-on:change="handleImageFileUpload()"
              />

              <div>
                <base-button size="sm" type="primary" @click="$refs.imageFile.click()">Add Photo</base-button>
              </div>
              <br/>

              <div v-for="(item, index) in new_model.safety_observation_photos" :key="index">
                <card>
                  <b-row align-v="center">
                    <b-col md="4">
                      <img
                        :src="item.photoPreviewSrc"
                        style="max-width:250px;max-height:250px;"
                        v-if="item.photoFile"
                      >

                      <img
                        :src="item.imageDataSrc"
                        style="max-width:250px;max-height:250px;"
                        v-if="item.imageDataSrc"
                      >
                    </b-col>

                    <b-col md="7">
                      <base-input
                        name="Observation Photo Remarks"
                        placeholder="Observation Photo Remarks"
                        v-model="item.photoRemarks">
                      </base-input>
                    </b-col>

                    <b-col md="1">
                      <base-button size="sm" type="primary" @click="deletePhoto(index)">Delete</base-button>
                    </b-col>
                  </b-row>
                </card>
              </div>
            </div>

            <div class="form-row">
              <b-col md="6">
                <base-input label="Need Correction Photos"
                            name="Need Correction Photos"
                            v-model="new_model.correction_photos_required">
                  <select class="form-control" v-model="new_model.correction_photos_required">
                    <option Selected disabled value="x">-- Please Select A Value --</option>
                    <option value="1">Yes</option>
                    <option value="0">No</option>                    
                  </select>
                </base-input>
              </b-col>

              <b-col md="6">
                <base-input label="Priority"
                            name="Priority"
                            v-model="new_model.safety_observation_priority_id">
                  <select class="form-control" v-model="new_model.safety_observation_priority_id">
                    <option Selected disabled value="x">-- Please Select A Priority --</option>
                    <option v-for="item in safety_observation_priority" :key="item.safety_observation_priority_id" v-bind:value="item.safety_observation_priority_id">{{ item.safety_observation_priority_name }} - {{ item.safety_observation_priority_duration }} Days</option>
                  </select>
                </base-input>
              </b-col>
            </div>

            <div>
              <base-button
                type="primary"
                class="my-4"
                @click="closeNewObservationItem()"
                >Cancel</base-button
              >
              <base-button
                type="primary"
                class="my-4"
                @click="saveNewObservationItem()"
                >Save</base-button
              >
            </div>
          </card>
        </template>
      </card>
    </modal>
  </card>  
</template>

<script>
  // import BaseSlider from '@/components/BaseSlider'
  import {LoginURL, clientId, clientSecret, apiDomain, getHeader, getcurrentDate} from '@/assets/js/config.js'
  import axios from 'axios'
  // import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'
  import jsonFormData from 'json-form-data'

  export default {
    created() {
      var userInfo = JSON.parse(window.localStorage.getItem("loginUser"));
      this.user_role = userInfo.role_id;
      this.user_id = userInfo.id;
      if (this.user_role == 12) {
        this.getContractorId();
      }
      this.safety_observation_id = this.$route.params.id;
      // this.getProjectListing();
      this.getUserList();
      this.getSafetyObservationTypes();
      this.getSafetyObservationCategories();
      this.getSafetyObservationPriorities();
      this.getSafetyObservationStatusNames();
      this.getSafetyObservationListing();
      this.getSafetyInspectionTypes();
    },
    data() {
      return {
        user_role: null,
        user_id: null,
        contractor_id: null,
        safetyObservationResponse: null,
        model: {
          safety_observation_id: null,
          project_id: 'x',
          group_id: 'x',
          site_id: 'x',
          contractor_id: 'x',
          project_status: null,
          safety_inspection_type_id: null,
          safety_observation_create_date: null,
          safety_observation_create_time: null,
          time_spent_hours: 1,
          safety_observation_items: [],
          status: null,
        },
        new_model: {
          observation_description: null,
          safety_observation_type_id: "x",
          safety_observation_category_id: "x",
          correction_photos_required: "x",
          safety_observation_priority_id: "x",
          safety_observation_photos: [
          ],
          safety_observation_status_id: 1,
        },
        projects: [],
        project_groups: [],
        project_sites: [],
        site_contractors: [],
        users: [],
        conducting_users: [],
        safety_inspection_type: [],
        safety_observation_type: [],
        safety_observation_category: [],
        safety_observation_priority: [],
        safety_observation_status: [],
        fields: [
          'no.',
          // Type
          {
            key: 'safety_observation_type_id',
            label: 'Type',
            formatter: (value, key, item) => {
              var findObj = this.safety_observation_type.find(obj => { return obj.safety_observation_type_id === value });
              if (typeof findObj !== 'undefined') {
                return findObj.safety_observation_type_code;
              } else {
                return value;
              }
            },
          },
          // Category
          {
            key: 'safety_observation_category_id',
            label: 'Category',
            formatter: (value, key, item) => {
              var findObj = this.safety_observation_category.find(obj => { return obj.safety_observation_category_id == value });
              if (typeof findObj !== 'undefined') {
                return findObj.safety_observation_category;
              } else {
                return value;
              }
            },
          },
          // Status
          {
            key: 'safety_observation_status_id',
            label: 'Status',
            formatter: (value, key, item) => {
              var findObj = this.safety_observation_status.find(obj => { return obj.status_id === value });
              if (typeof findObj !== 'undefined') {
                return findObj.status_code;
              } else {
                return value;
              }
            },
          },
          // Priority
          {
            key: 'safety_observation_priority_id',
            label: 'Priority',
            formatter: (value, key, item) => {
              var findObj = this.safety_observation_priority.find(obj => { return obj.safety_observation_priority_id === value });
              if (typeof findObj !== 'undefined') {
                return findObj.safety_observation_priority_name;
              } else {
                return value;
              }
            },
          },
          // Target Date
          {
            key: 'target_date',
            label: 'Target Date',
            formatter: (value, key, item) => {
              if (value == '') {
                return 'N/A';
              } else {
                return value;
              }
            },
          },
          // Actions
          { key: 'actions', label: 'Actions' },
        ],
        isNewObservationItemVisible: false,
        safety_observation_item_mode: 'create',
        current_item_index: null,
      }
    },
    methods: {
      getProjectListing(){
        if (this.user_role == 12) {
          this.getProjectListingContractor();
        } else {
          this.getProjectListingNormal();
        }
      },
      getProjectListingNormal()
      {
        axios.get(apiDomain + 'project/project', { headers: getHeader() })
        .then ( response => {
        if (response.status === 200)
        {
            this.projects = response.data.data;
        }
        })
        .catch ( error => {
        console.log(error.response.status)
        this.errorFunction(error, "Project Listing")
        });
      },
      getProjectListingContractor()
      {
        axios.get(apiDomain + 'project/project.show_by_contractor/'  + this.contractor_id, { headers: getHeader() })
        .then ( response => {
        if (response.status === 200)
        {
            this.projects = response.data.data;
        }
        })
        .catch ( error => {
        console.log(error.response.status)
        this.errorFunction(error, "Project Listing Contractor")
        });
      },
      getGroupFromProject(){
        if (this.user_role == 12) {
          this.getGroupFromProjectContractor();
        } else {
          this.getGroupFromProjectNormal();
        }
      },
      getGroupFromProjectNormal()
      {
        axios.get(apiDomain + 'project/project_group.show_by_project/' + this.model.project_id , { headers: getHeader() })
        .then ( response => {
          if (response.status === 200)
          {
            this.project_groups = response.data.data;
            // this.model.group_id = 'y';
            // this.model.site_id = 'x';
            // this.project_sites = [];
          }
        })
        .catch ( error => {
          console.log(error.response.status);
        } );
      },
      getGroupFromProjectContractor()
      {
        axios.get(apiDomain + 'project/project_group.show_by_project_by_contractor/' + this.model.project_id + '/' + this.contractor_id, { headers: getHeader() })
        .then ( response => {
          if (response.status === 200)
          {
            this.project_groups = response.data.data;
            // this.model.group_id = 'y';
            // this.model.site_id = 'x';
            // this.project_sites = [];
          }
          })
        .catch ( error => {
        console.log(error.response.status)
        });
      },
      getSiteFromGroup(){
        if (this.user_role == 12) {
          this.getSiteFromGroupContractor();
        } else {
          this.getSiteFromGroupNormal();
        }
      },
      getSiteFromGroupNormal()
      {
        axios.get(apiDomain + 'project/project_site.show_by_group/' + this.model.group_id , { headers: getHeader() })
        .then ( response => {
          if (response.status === 200)
          {
            this.project_sites = response.data.data;
            // this.model.site_id = 'y';
          }
        })
        .catch ( error => {
          console.log(error.response.status);
        } );
      },
      getSiteFromGroupContractor()
      {
        axios.get(apiDomain + 'project/project_site.show_by_group_by_contractor/' + this.model.group_id + '/' + this.contractor_id, { headers: getHeader() })
        .then ( response => {
          if (response.status === 200)
          {
            this.project_sites = response.data.data;
            // this.model.site_id = 'y';
          }
          })
        .catch ( error => {
        console.log(error.response.status)
        });
      },
      getContractorFromSite()
      {
        axios.get(apiDomain + 'project/site_contractor.show_by_site/' + this.model.site_id , { headers: getHeader() })
        .then ( response => {
          if (response.status === 200)
          {
            this.site_contractors = response.data.data;
            var index = this.site_contractors.findIndex(x => x.active_status ==="ACTIVE");
            this.model.contractor_id = this.site_contractors[index].contractor.contractor_id;
          }
        })
        .catch ( error => {
          console.log(error.response.status)
          this.errorFunction(error, "Site Contractor Information")
        } );
      },
      getUserList(){
        // if (this.user_role == 12) {
        //   this.getUserListContractor();
        // } else {
          this.getUserListNormal();
        // }
      },
      getUserListNormal()
      {
        axios.get(apiDomain + 'lookup/user_list', { headers: getHeader() })
          .then ( response => {
          if (response.status === 200)
          {
            this.users = response.data.data;
            this.addUserToConducting();
          }
          })
          .catch ( error => {
            this.errorFunction(error, 'User Listing')
          });
      },
      getUserListContractor()
      {
        axios.get(apiDomain + 'lookup/user_list.contractor/' + this.contractor_id, { headers: getHeader() })
          .then ( response => {
          if (response.status === 200)
          {
            this.users = response.data.data;
            this.addUserToConducting();
          }
          })
          .catch ( error => {
            this.errorFunction(error, 'User Listing')
          });
      },
      getSafetyObservationTypes()
      {
        axios.get(apiDomain + 'lookup/safety_observation_type', { headers: getHeader() })
        .then ( response => {
          if (response.status === 200)
          {
            this.safety_observation_type = response.data.data;
          }
        })
        .catch ( error => {
          console.log(error.response.status)
          this.errorFunction(error, "Safety Observation Type Information")
        } );
      },
      getSafetyInspectionTypes()
      {
        axios.get(apiDomain + 'lookup/safety_inspection_type', { headers: getHeader() })
        .then ( response => {
          if (response.status === 200)
          {
            this.safety_inspection_type = response.data.data;
          }
        })
        .catch ( error => {
          console.log(error.response.status)
          this.errorFunction(error, "Safety Inspection Type Information")
        } );
      },
      getSafetyObservationCategories()
      {
        axios
        .get(apiDomain + "setup/safety_observation_category_active", { headers: getHeader() })
        .then((response) => {
          if (response.status === 200) {
            this.safety_observation_category = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "Safety Observation Category");
        });
      },
      getSafetyObservationPriorities()
      {
        axios
        .get(apiDomain + "setup/safety_observation_priority_active", { headers: getHeader() })
        .then((response) => {
          if (response.status === 200) {
            this.safety_observation_priority = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "Safety Observation Priority");
        });
      },
      getSafetyObservationStatusNames()
      {
        axios
        .get(apiDomain + "lookup/safety_observation_status", { headers: getHeader() })
        .then((response) => {
          if (response.status === 200) {
            this.safety_observation_status = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "Safety Observation Status");
        });
      },
      populateConductingUsers(conducting_users_array)
      {
        // safety_observation_conducting_user SORT THIS ARRAY BY safety_observation_conducting_user_id
        this.conducting_users = [];
        conducting_users_array.forEach((item) => {
          this.conducting_users.push({ userId: item.safety_observation_conducting_user.id });
          this.users.map((e_rol) => {
            if (e_rol.id == item.safety_observation_conducting_user.id) {
              e_rol["disabled"] = true;
              e_rol["v_hide"] = true;
            }
          });
        });
      },
      removeFromList(event) {
        this.conducting_users.map((e_rev) => {
          if (e_rev.userId == event.target.value) {
            if (e_rev.previousSelection != "") {
              this.users.map((e_rol) => {
                if (e_rol.id == e_rev.previousSelection) {
                  e_rol["disabled"] = false;
                  e_rol["v_hide"] = false;
                }
              });
            }
          }
          this.users.map((e_rol) => {
            if (e_rol.id == event.target.value) {
              e_rol["disabled"] = true;
              e_rol["v_hide"] = true;
            }
          });
          e_rev.previousSelection = e_rev.userId;
        });
      },
      addField(object, fieldType) {
        let newObject = {};
        for (const [key, value] of Object.entries(object)) {
          newObject[key] = "";
        }
        fieldType.push(newObject);
      },
      removeField(index, fieldType) {
        if (fieldType[index].userId != "") {
          this.users.map((e_rol) => {
            if (e_rol.id == fieldType[index].userId) {
              e_rol["disabled"] = false;
              e_rol["v_hide"] = false;
            }
          });
        }
        fieldType.splice(index, 1);
      },
      convertDateFormat(inputDateString){
        // inputDateString format e.g. "25-May-2023"
        console.log('convertDateFormat(inputDateString){');
        console.log(inputDateString);

        // Helper function to map month name to month number
        const getMonthNumber = (monthName) => {
          const monthNames = [
            "Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
          ];
          return monthNames.indexOf(monthName);
        };

        // Split the date string by the hyphen (-) to extract the day, month, and year
        const parts = inputDateString.split("-");
        const day = parseInt(parts[0], 10);
        const monthName  = parts[1];
        const year = parseInt(parts[2], 10);

        const month = getMonthNumber(monthName);

        console.log(parts, day, month, year);

        // Create a new Date object by specifying the year, month (zero-based), and day
        const dateObject = new Date(year, month, day);

        const newDay = ("0" + dateObject.getDate()).slice(-2);
        const newMonth = ("0" + (dateObject.getMonth() + 1)).slice(-2);
        const newYear = dateObject.getFullYear();

        // Format the date to the desired format "YYYY-MM-DD"
        const formattedDate = newYear + '-' + newMonth + '-' + newDay;

        console.log(formattedDate);

        return formattedDate;
      },
      getSafetyObservationListing()
      {
        axios.get(apiDomain + 'health_safety/safety_observation/' + this.safety_observation_id, { headers: getHeader() })
        .then ( response => {
        if (response.status === 200)
        {
          this.safetyObservationResponse = response.data.data;
          this.model.project_id = response.data.data[0].project_id;
          this.model.group_id = response.data.data[0].group_id;
          this.model.site_id = response.data.data[0].site_id;
          this.model.contractor_id = response.data.data[0].contractor.contractor_id;
          this.model.project_status = response.data.data[0].project_status;
          // this.model.safety_inspection_type_id = response.data.data[0].safety_inspection_type.safety_inspection_type_id;
          this.model.safety_inspection_type_id = response.data.data[0].safety_inspection_type != null ? response.data.data[0].safety_inspection_type.safety_inspection_type_id : null;
          // console.log(response.data.data[0].safety_observation_create_date);
          // console.log(typeof response.data.data[0].safety_observation_create_date);
          this.model.safety_observation_create_date = this.convertDateFormat(response.data.data[0].safety_observation_create_date_web);
          this.model.safety_observation_create_time = response.data.data[0].safety_observation_create_time;
          this.model.time_spent_hours = response.data.data[0].time_spent_hours;
          this.populateConductingUsers(response.data.data[0].conducting_users);
          this.model.safety_observation_items = response.data.data[0].safety_observation_items;
          this.loadAllSafetyObservationPhotos();
          this.getProjectListing();
          this.getGroupFromProject();
          this.getSiteFromGroup();
          this.getContractorFromSite();
          this.model.status = response.data.data[0].status;
        }
        })
        .catch ( error => {
          console.log(error.response.status)
          this.errorFunction(error, "Safety Observation Information")
        });
      },
      showNewObservationItem() {
        this.isNewObservationItemVisible = true;
      },
      closeNewObservationItem() {
        this.new_model = {
          observation_description: null,
          safety_observation_type_id: "x",
          safety_observation_category_id: "x",
          correction_photos_required: "x",
          safety_observation_priority_id: "x",
          safety_observation_photos: [
          ],
          safety_observation_status_id: 1,
        },
        this.isNewObservationItemVisible = false;
      },
      getContractorId(){
        axios.get(apiDomain + 'lookup/contractor.contractor_id/' + this.user_id, { headers: getHeader() })
          .then ( response => {
          if (response.status === 200)
          {
            this.contractor_id = response.data.contractor_id;
          }
          })
          .catch ( error => {
            this.errorFunction(error, 'Contractor Id')
          });
      },
      // handleDetails(index, row)
      // {
      //   // if draft redirect to edit
      //   // if submitted redirect to view items page
      //   if (row.status == 1) {
      //     this.$router.push({path: 'safetyObservationView/'+row.safety_observation_id })
      //   } else {
      //     this.$router.push({path: 'safetyObservationEdit/'+row.safety_observation_id });
      //   }
          
      // },
      viewObservationItem(item, index, button) {

        // 20230426 REQUIREMENT CHANGE, ALLOW SAFETY OFFICER TO SUBMIT CORRECTION

        // IF STATUS IS OPEN
        //   IF PROJECT IS COMPLETED OR APPROVED
        //     IF USER IS ONM OPEN PAGE TO SUBMIT CORRECTION
        //     ELSE VIEW DETAILS
        //   ELSE
        //     IF USER IS EPC OPEN PAGE TO SUBMIT CORRECTION
        //     ELSE VIEW DETAILS
          
        // ELSE IF PENDING APPROVAL
          // IF USER IS SAFETY OFFICER OPEN PAGE FOR APPROVAL
          // IF USER IS EPC OPEN PAGE TO EDIT CORRECTION
        // ELSE IF STATUS IS CLOSED
          // VIEW ONLY
        // ELSE VIEW ONLY?

        // GET PROJECT STATUS
        var project_index = this.projects.findIndex(item => item.project_id == this.model.project_id);
        var project_status_id = this.projects[project_index].status.status_id;

        // IF STATUS IS OPEN
        if (item.safety_observation_status_id == 2) {
          // IF PROJECT IS COMPLETED OR APPROVED
          if (project_status_id == 5 || project_status_id == 7) {
            // IF USER IS ONM OPEN PAGE TO SUBMIT CORRECTION | NEW REQUIREMENT, OR SAFETY OFFICER
            if (this.user_role == 18 || this.user_role == 16) {
              this.$router.push({path: '/healthSafety/safetyObservationItemCorrection/' + item.safety_observation_item_id });              
            } else {
              this.$router.push({path: '/healthSafety/safetyObservationItemView/' + item.safety_observation_item_id });
            }
          } else {
            // IF USER IS EPC OPEN PAGE TO SUBMIT CORRECTION
            if (this.user_role == 12) {
              // IF USER BELONGS TO ASSIGNED CONTRACTOR | NEW REQUIREMENT, OR SAFETY OFFICER
              if (this.contractor_id == this.model.contractor_id) {
                this.$router.push({path: '/healthSafety/safetyObservationItemCorrection/' + item.safety_observation_item_id });
              } else {
                this.$router.push({path: '/healthSafety/safetyObservationItemView/' + item.safety_observation_item_id });
              }
            } else if (this.user_role == 16) {
              this.$router.push({path: '/healthSafety/safetyObservationItemCorrection/' + item.safety_observation_item_id });
            }
            else {
              this.$router.push({path: '/healthSafety/safetyObservationItemView/' + item.safety_observation_item_id });
            }
          }
        } else if (item.safety_observation_status_id == 4) {
          this.$router.push({path: '/healthSafety/safetyObservationItemCorrection/' + item.safety_observation_item_id });          
        } else {
          this.$router.push({path: '/healthSafety/safetyObservationItemView/' + item.safety_observation_item_id });
        }
      },
      saveNewObservationItem() {
        if (
          !this.new_model.observation_description ||
          this.new_model.safety_observation_type_id == "x" ||
          this.new_model.safety_observation_category_id == "x" ||
          this.new_model.correction_photos_required == "x" ||
          this.new_model.safety_observation_priority_id == "x"
        ) {
          if (!this.new_model.observation_description) {
            this.redErrorNotify(
              "<b>Invalid Input : Observation Remarks is a Mandatory Field in Safety Observation </b>"
            );
          } else if (this.new_model.safety_observation_type_id == "x") {
            this.redErrorNotify(
              "<b>Invalid Input : Safety Observation Type is a Mandatory Field in Safety Observation </b>"
            );
          } else if (this.new_model.safety_observation_category_id == "x") {
            this.redErrorNotify(
              "<b>Invalid Input : Safety Observation Category is a Mandatory Field in Safety Observation </b>"
            );
          } else if (this.new_model.correction_photos_required == "x") {
            this.redErrorNotify(
              "<b>Invalid Input : Need Correction Photos is a Mandatory Field in Safety Observation </b>"
            );
          } else if (this.new_model.safety_observation_priority_id == "x") {
            this.redErrorNotify(
              "<b>Invalid Input : Safety Observation Priority is a Mandatory Field in Safety Observation </b>"
            );
          } else {
            this.redErrorNotify(
              "<b>Invalid Input : Please fill in all the Mandatory Fields in Safety Observation </b>"
            );
          }
        } else {
          if (this.safety_observation_item_mode == 'create') {
            this.model.safety_observation_items.push(this.new_model);
          } else if (this.safety_observation_item_mode == 'edit') {
            this.model.safety_observation_items[this.current_item_index] = this.new_model;
            this.current_item_index = null;
          }

          this.safety_observation_item_mode = 'create';

          this.closeNewObservationItem();
        }
      },
      getSafetyObservationStatus(status_id) {
        var findObj = this.safety_observation_status.find(obj => { return obj.status_id === status_id });
        if (typeof findObj !== 'undefined') {
          return findObj.status_code;
        } else {
          return status_id;
        }
      },
      loadAllSafetyObservationPhotos() {
        this.model.safety_observation_items.forEach((e) => {
          e['safety_observation_photos'].forEach((el) => {
            if (el['image']['url']) {
              axios.get(apiDomain + el['image']['url'], { headers: getHeader() })
              .then ( response => {
                if (response.status === 200)
                {
                  if (response.data.data.image && response.data.data.image) {
                    el['imageDataSrc'] = `data:${response.data.data.image};base64,${response.data.data.image}`;
                  } else {
                    el['imageDataSrc'] =  null;
                  }
                }
              })
              .catch ( error => {
                console.log(error.response.status)
              });
            }
          });
        });
      },
      handleImageFileUpload() {
        let vm = this;
        this.new_model.safety_observation_photos.push(
          {
            photoFile: {},
            photoPreviewSrc: "",
            photoRemarks: "",
          },
        );
        console.log(this.$refs.imageFile.files[0]);
        this.new_model.safety_observation_photos.at(-1).photoFile = this.$refs.imageFile.files[0];
        console.log(this.new_model.safety_observation_photos.at(-1).photoFile);

        var reader = new FileReader();
        reader.onload = function(e) {
          vm.new_model.safety_observation_photos.at(-1).photoPreviewSrc = e.target.result;
        };

        reader.readAsDataURL(this.new_model.safety_observation_photos.at(-1).photoFile);

      },
      deletePhoto(index){
        if (typeof this.new_model.safety_observation_photos[index]['safety_observation_item_file_id'] !== "undefined" ) {
          if (typeof this.new_model.safety_observation_item_files_to_be_deleted == "undefined") {
            this.new_model.safety_observation_item_files_to_be_deleted = [];
          }
          this.new_model.safety_observation_item_files_to_be_deleted.push(this.new_model.safety_observation_photos[index]['safety_observation_item_file_id']);
        }
        this.new_model.safety_observation_photos.splice(index, 1);
      },
      deleteObservationItem(item, index, button) {
        if (typeof this.model.safety_observation_items[index].safety_observation_item_id !== "undefined" ) {
          if (typeof this.model.safety_observation_items_to_be_deleted == "undefined") {
            this.model.safety_observation_items_to_be_deleted = [];
          }
          this.model.safety_observation_items_to_be_deleted.push(this.model.safety_observation_items[index].safety_observation_item_id);
        }
        this.model.safety_observation_items.splice(index, 1);
      },
      checkAndCreateConductingUsers() {
        let tempConductingUsers = [];
        this.conducting_users.forEach((item) => {
          if (item.userId != "") {
            tempConductingUsers.push({ userId: item.userId });
          }
        });
        this.model.conducting_users = tempConductingUsers;
      },
      checkForRequiredData() {
        this.checkAndCreateConductingUsers();
        var checker = false;
        if (
          this.model.project_id == 'x' ||
          this.model.group_id == 'x' ||
          this.model.site_id == 'x' ||
          this.model.contractor_id == 'x' ||
          !this.model.safety_observation_create_date ||
          !this.model.safety_observation_create_time ||
          this.model.safety_observation_items.length < 1
        ) {
          if (this.model.project_id == 'x') {
            this.redErrorNotify(
              "<b>Invalid Input : Project is a Mandatory Field in Safety Observation </b>"
            );
          } else if (this.model.group_id == 'x') {
            this.redErrorNotify(
              "<b>Invalid Input : Project Group is a Mandatory Field in Safety Observation </b>"
            );          
          } else if (this.model.site_id == 'x') {
            this.redErrorNotify(
              "<b>Invalid Input : Project Site is a Mandatory Field in Safety Observation </b>"
            );          
          } else if (this.model.contractor_id == 'x') {
            this.redErrorNotify(
              "<b>Invalid Input : EPC (Project Site Contractor) is a Mandatory Field in Safety Observation </b>"
            );          
          } else if (!this.model.safety_observation_create_date) {
            this.redErrorNotify(
              "<b>Invalid Input : Date is a Mandatory Field in Safety Observation </b>"
            );          
          } else if (!this.model.safety_observation_create_time) {
            this.redErrorNotify(
              "<b>Invalid Input : Time is a Mandatory Field in Safety Observation </b>"
            );          
          } else if (this.model.safety_observation_items.length < 1) {
            this.redErrorNotify(
              "<b>Invalid Input : Safety Observation Item is a Mandatory Field in Safety Observation </b>"
            );
          } else {
            this.redErrorNotify(
              "<b>Invalid Input : Please fill in all the Mandatory Fields in Safety Observation </b>"
            );
          }        
        } else {
          console.log('passed all checks');
          checker = true;
        }
        return checker;
      },
      saveAsDraft(){
        if (this.checkForRequiredData()) {
          console.log('save as draft');

          this.model.safety_observation_id = this.safety_observation_id;
          this.model.status = 0;

          let formData = jsonFormData(this.model);
          formData.append("_method", "put");

          console.log(typeof formData);

          const tokenData = JSON.parse(window.localStorage.getItem("authUser"));
          const headers = {
            Authorization: "Bearer " + tokenData.access_token,
          };

          axios.post(apiDomain + 'health_safety/safety_observation/'+ this.safety_observation_id, formData, { headers })
          .then((response) => {
            if (response.status === 200) {
              this.$notify({
              message:
                '<b>Safety Observation Successfully Updated and Saved As Draft.</b>',
              timeout: 10000,
              icon: 'ni ni-bell-55',
              type: 'default',
              });
              this.$router.back()
            }
          })
          .catch((error) => {
            this.errorFunction(error, "Update Safety Observation Draft");
          });

        }
      },
      submitData(){
        if (this.checkForRequiredData()) {
          console.log('submit data');

          this.model.safety_observation_id = this.safety_observation_id;
          this.model.status = 1;

          let formData = jsonFormData(this.model);
          formData.append("_method", "put");

          console.log(typeof formData);

          const tokenData = JSON.parse(window.localStorage.getItem("authUser"));
          const headers = {
            Authorization: "Bearer " + tokenData.access_token,
          };

          axios.post(apiDomain + 'health_safety/safety_observation/'+ this.safety_observation_id, formData, { headers })
          .then((response) => {
            if (response.status === 200) {
              this.$notify({
              message:
                '<b>Safety Observation Successfully Updated and Submitted.</b>',
              timeout: 10000,
              icon: 'ni ni-bell-55',
              type: 'default',
              });
              this.$router.back()
            }
          })
          .catch((error) => {
            this.errorFunction(error, "Update Safety Observation Submit");
          });

        }
      },
    },
  }
</script>

<style>
.form-control {
  color: black;
}
.form-row {
  color: black;
}
select option {
  color: black;
}
textarea {
  color: black;
}
</style>